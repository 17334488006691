import { add } from 'date-fns'

// TODO https://virtualgamingworlds.atlassian.net/browse/MF-1150
// Replace with date-fns parseISODuration, when new version is released

function parseISODuration(duration: string) {
  const regex =
    /P(?:(\d+)Y)?(?:(\d+)M)?(?:(\d+)D)?T?(?:(\d+)H)?(?:(\d+)M)?(?:(\d+(?:\.\d+)?)S)?/
  const matches = duration.match(regex)

  if (!matches) {
    throw new Error('Invalid ISO 8601 duration format')
  }

  const years = parseInt(matches[1] || '0', 10)
  const months = parseInt(matches[2] || '0', 10)
  const days = parseInt(matches[3] || '0', 10)
  const hours = parseInt(matches[4] || '0', 10)
  const minutes = parseInt(matches[5] || '0', 10)
  const seconds = parseFloat(matches[6] || '0')

  return { years, months, days, hours, minutes, seconds }
}

export function durationToDate(duration: string, baseDate = new Date()) {
  return add(baseDate, parseISODuration(duration))
}
