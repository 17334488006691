import * as ToastPrimitive from '@radix-ui/react-toast'
import { cn } from '@vgw/tailwind-merger'
import { ComponentProps } from 'react'

type ToastTitleProps = ComponentProps<typeof ToastPrimitive.Title>

const ToastTitle = ({ className, ...props }: ToastTitleProps) => {
  return (
    <ToastPrimitive.Title
      {...props}
      className={cn('text-lg font-bold leading-7', className)}
    />
  )
}

export { ToastTitle }
