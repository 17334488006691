import { useQuery } from '@tanstack/react-query'
import { getAllOptions } from '@/apis/periodic-bonus-service'

function useBonusDefinitionsQuery() {
  const options = getAllOptions({ headers: { Authorization: null } })

  const { data, isLoading, error } = useQuery(options)

  const { definitions = [] } = data || {}

  return {
    error,
    isLoading,
    definitions,
  }
}

export { useBonusDefinitionsQuery }
