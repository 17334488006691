import { MonthCaptionProps, useDayPicker } from 'react-day-picker'
import YearSelectButton from './year-select-button' // Adjust the import path as necessary
import { IconButton } from '@vgw/multibrand-fe-icon-button'
import { cn } from '@vgw/tailwind-merger'
import { useContext } from 'react'
import { CalendarContext } from './calendar.context'
import { useLingui } from '@lingui/react/macro'

export const Caption = ({ calendarMonth }: MonthCaptionProps) => {
  const { goToMonth, nextMonth, previousMonth } = useDayPicker()
  const { t } = useLingui()

  const { size } = useContext(CalendarContext)

  return (
    <div className="flex h-8 justify-between">
      <YearSelectButton calendarMonth={calendarMonth} />
      <div
        className={cn(
          'flex items-center justify-center',
          size === 'sm' ? 'gap-6' : 'gap-4',
        )}
      >
        <IconButton
          icon="chevron-left"
          variant="ghost"
          size={size}
          type="button"
          onClick={() => previousMonth && goToMonth(previousMonth)}
          aria-label={t`Previous month`}
        />
        <IconButton
          icon="chevron-right"
          variant="ghost"
          size={size}
          type="button"
          onClick={() => nextMonth && goToMonth(nextMonth)}
          aria-label={t`Next month`}
        />
      </div>
    </div>
  )
}
