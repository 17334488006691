import { ComponentProps, ElementRef, forwardRef } from 'react'
import { Overlay as PrimitiveOverlay } from '@radix-ui/react-dialog'
import { cn } from '@vgw/tailwind-merger'

const Overlay = forwardRef<
  ElementRef<typeof PrimitiveOverlay>,
  ComponentProps<typeof PrimitiveOverlay>
>(({ className, ...props }, ref) => (
  <PrimitiveOverlay
    className={cn(
      [
        'bg-overlay-dark fixed inset-0 z-40',
        'data-[state=open]:animate-in data-[state=open]:fade-in-0 transition-opacity',
        'data-[state=closed]:animate-out data-[state=closed]:fade-out-0',
      ],
      className,
    )}
    data-testid="drawer-overlay"
    ref={ref}
    {...props}
  />
))

Overlay.displayName = 'DrawerOverlay'

export { Overlay }
