import { Link } from 'react-router-dom'
import { GAME_PLAY_PATH } from '@/config/paths'
import { GameTile, Jackpot } from '@vgw/multibrand-fe-game-tile'
import { cn } from '@vgw/tailwind-merger'
import { type LayoutVariant } from './layout-factory'
import { MoreInfoButton } from '@vgw/multibrand-fe-more-info-button'
import { useSelectedCoinType } from '@/use-selected-coin-type'
import { useGameInfoDrawerLaunch } from '@/features/game-info-drawer/use-game-info-drawer-launch'

type LayoutItemCardProps = {
  name: string
  gameId: string
  variant: LayoutVariant
  launchUrl: string
  imageUrl: string
  jackpots: Jackpot[]
  className?: string
}

export const LayoutItemCard = ({
  name,
  gameId,
  variant,
  imageUrl,
  className,
  launchUrl,
  jackpots,
}: LayoutItemCardProps) => {
  const selectedCoinType = useSelectedCoinType()
  const { open } = useGameInfoDrawerLaunch()

  return (
    <div className="relative h-full">
      <MoreInfoButton
        onClick={() => open(gameId)}
        className="absolute right-2 top-2 z-10"
      />
      <Link
        data-testid="game-item-card"
        key={gameId}
        to={`${GAME_PLAY_PATH}/${gameId}`}
        state={{ launchUrl: launchUrl }}
        className={cn(
          [
            'relative',
            'inline-block',
            'h-full',
            'outline-none',

            'before:absolute',
            'before:inset-0',

            'focus-visible:before:shadow-[inset_0_0_0_2px]',
            'focus-visible:before:shadow-button-border-focus',
            'focus-visible:before:z-10',

            'hover:[&_img]:scale-105',
            'hover:[&_img]:contrast-125',
          ],
          {
            'rounded-radius-base before:rounded-radius-base':
              variant !== 'round',
            'rounded-radius-icon-button before:rounded-radius-icon-button':
              variant === 'round',
          },
        )}
      >
        <GameTile
          name={name}
          imageUrl={imageUrl}
          variant={variant}
          jackpots={jackpots}
          className={cn('h-full', className)}
          coinType={selectedCoinType}
        />
      </Link>
    </div>
  )
}
