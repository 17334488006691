import { forwardRef, HTMLAttributes } from 'react'
import { cva, type VariantProps } from 'class-variance-authority'
import { cn } from '@vgw/tailwind-merger'

import { TextFieldContext } from './text-field.context'

const textFieldVariants = cva(
  cn(
    'relative',
    'w-full',

    'flex',

    'bg-form-input-surface',

    'rounded-radius-form',

    'text-form-input-content-base',

    'before:pointer-events-none',

    'before:absolute',
    'before:bottom-0',
    'before:left-0',
    'before:right-0',

    'before:border-b',
    'before:border-solid',

    'before:content-[""]',

    'before:transition-border-color',
    'before:duration-300',
    'before:z-10',
    'overflow-hidden',
  ),
  {
    variants: {
      size: {
        sm: '',
        md: '',
      },
    },
    defaultVariants: {
      size: 'sm',
    },
  },
)

export interface TextFieldProps
  extends HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof textFieldVariants> {
  error?: boolean
  icon?: React.ReactNode
  prefixComponent?: React.ReactNode
}

export const TextField = forwardRef<HTMLDivElement, TextFieldProps>(
  (
    {
      className,
      size,
      error = false,
      children,
      icon,
      prefixComponent,
      ...props
    },
    ref,
  ) => {
    return (
      <TextFieldContext.Provider
        value={{
          hasIcon: !!icon,
          error,
          size: size ?? 'sm',
        }}
      >
        <div
          data-testid="text-field"
          className={cn(textFieldVariants({ size, className }), {
            'focus-within:before:border-form-input-content-label-active focus-within:before:border-b-2':
              !error,
            'before:border-status-error before:border-b-2': error,
          })}
          ref={ref}
          {...props}
        >
          {prefixComponent}
          <div className="relative flex w-full items-center">
            {children}
            {icon && <div className="absolute right-2">{icon}</div>}
          </div>
        </div>
      </TextFieldContext.Provider>
    )
  },
)

TextField.displayName = 'TextField'
