import { PlayForFreeContentItem } from '@/features/store/play-for-free/play-for-free-content/play-for-free-content-item'
import { msg } from '@lingui/core/macro'
import { useLingui } from '@lingui/react'
import competitionIcon from '../assets/competition.png'
import competitionIconX2 from '../assets/competition-x2.png'
import groupIcon from '../assets/group.png'
import groupIconX2 from '../assets/group-x2.png'
import postalRequestCompleteIcon from '../assets/postal-request-complete.png'
import postalRequestCompleteIconX2 from '../assets/postal-request-complete-x2.png'

const icons = [
  {
    iconUrl: competitionIcon,
    srcSet: `${competitionIcon} 55w, ${competitionIconX2} 110w`,
    title: msg`Top Up your GC`,
    description: msg`Free GC top up when you’re running low`,
  },
  {
    iconUrl: groupIcon,
    srcSet: `${groupIcon} 55w, ${groupIconX2} 110w`,
    title: msg`Social Contests`,
    description: msg`Enter a facebook SC no-cost giveaway contest`,
  },
  {
    iconUrl: postalRequestCompleteIcon,
    srcSet: `${postalRequestCompleteIcon} 55w, ${postalRequestCompleteIconX2} 110w`,
    title: msg`Postal Request`,
    description: msg`Enter a facebook SC no-cost giveaway contest`,
  },
]

export const PlayForFreeContent = () => {
  const { _ } = useLingui()

  return (
    <div className="flex flex-col gap-3">
      {icons.map(({ iconUrl, title, description, srcSet }) => (
        <PlayForFreeContentItem
          key={iconUrl}
          iconUrl={iconUrl}
          title={_(title)}
          srcSet={srcSet}
          description={_(description)}
        />
      ))}
    </div>
  )
}
