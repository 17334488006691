import React, { useContext } from 'react'
import { cn } from '@vgw/tailwind-merger'
import { TextFieldContext } from '../text-field.context'

export interface LabelProps {
  htmlFor: string
  children: React.ReactNode
}

const Label: React.FC<LabelProps> = ({ htmlFor, children }) => {
  const { error, size } = useContext(TextFieldContext)

  return (
    <label
      htmlFor={htmlFor}
      className={cn(
        'text-content-placeholder pointer-events-none absolute transition-all',
        'peer-disabled:peer-placeholder-shown:text-content-disabled peer-autofill:text-content-placeholder peer-placeholder-shown:top-auto',
        {
          'left-2 top-[5px] text-[10px] peer-placeholder-shown:text-sm peer-focus:top-[5px] peer-focus:text-[10px]':
            size === 'sm',
          'left-3 top-2 text-[11px] peer-placeholder-shown:text-base peer-focus:top-2 peer-focus:text-[11px]':
            size === 'md',
          'text-status-error': error,
          'peer-focus:text-form-input-content-label-active': !error,
        },
      )}
    >
      {children}
    </label>
  )
}

Label.displayName = 'TextField.Label'

export { Label }
