import { getBrandConfig } from '@/config/config'
import { AuthInterceptor } from '@/features/oidc/auth-interceptor'

export * from './__generated__/@tanstack/react-query.gen'
export * from './__generated__/types.gen'
export * from './__generated__/sdk.gen'
import { client } from './__generated__/sdk.gen'

const brandConfig = getBrandConfig()

client.setConfig({
  baseUrl: brandConfig.payPurchasesApiUrl,
})

client.interceptors.request.use(AuthInterceptor)
