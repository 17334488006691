import { Label } from './label'
import { CustomIcon, IconNames } from '@vgw/multibrand-fe-icon'
import { msg } from '@lingui/core/macro'
import { Trans } from '@lingui/react'

const volatility = {
  HIGH: {
    label: msg`High volatility`,
    icon: 'coloured:volatility-high' satisfies IconNames,
  },
  MEDIUM: {
    label: msg`Medium volatility`,
    icon: 'coloured:volatility-medium' satisfies IconNames,
  },
  LOW: {
    label: msg`Low volatility`,
    icon: 'coloured:volatility-low' satisfies IconNames,
  },
} as const

type VolatilityProps = {
  level: keyof typeof volatility
}

export const Volatility = ({ level }: VolatilityProps) => {
  return (
    <>
      <CustomIcon size="lg" name={volatility[level].icon} />
      <Label data-testid="game-info-volatility">
        <Trans id={volatility[level].label.id} />
      </Label>
    </>
  )
}
