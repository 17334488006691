import { Trans } from '@lingui/react/macro'
import { Button } from '@vgw/multibrand-fe-button'
import { cva, type VariantProps } from 'class-variance-authority'
import {
  GcFormatter,
  ScFormatter,
} from '@/features/price-formatter-utils/store-price-formatter'
import { CustomIcon, IconNames } from '@vgw/multibrand-fe-icon'
import { useCountdownDuration } from '@/features/datetime-utils/use-countdown-duration'
import { useDurationFormat } from '@/features/datetime-utils/use-duration-format'
import { cn } from '@vgw/tailwind-merger'
import { durationToDate } from '@/features/datetime-utils/duration-to-date'
import { useMemo } from 'react'
import { PeriodicBonusImage } from '@/features/periodic-bonus/periodic-bonus-item/periodic-bonus-image'

const periodicBonusVariants = cva(
  'flex w-[116px] flex-col rounded-md border-2 p-2 text-center',
  {
    variants: {
      type: {
        Claimable: '',
        Pending: '',
        Claimed: 'border-muted text-content-disabled grayscale',
      },
      variant: {
        Normal: '',
        Special: 'bg-gradient-to-b from-[#fffabd] to-[#ffe668]',
      },
    },
    compoundVariants: [
      {
        variant: 'Normal',
        type: 'Claimable',
        className: 'border-yellow-300 bg-yellow-50 shadow-periodic-bonus-item',
      },
      {
        variant: 'Normal',
        type: 'Pending',
        className:
          'border-surface-base bg-surface-base text-content-muted shadow-periodic-bonus-item',
      },
      {
        variant: 'Normal',
        type: 'Claimed',
        className:
          'border-surface-base bg-surface-base shadow-periodic-bonus-item',
      },
      {
        variant: 'Special',
        type: ['Claimable', 'Pending'],
        // TODO: change these colors to get from tailwind, colors in designs are broken
        className:
          'relative border-transparent bg-clip-padding before:absolute before:-inset-[2px] before:z-[-1] before:block before:rounded-md before:bg-gradient-to-b before:from-[#ffec00] before:to-[#e5a200] after:absolute after:-inset-0 after:z-[-1] after:rounded-md after:bg-white',
      },
    ],

    defaultVariants: {
      type: 'Claimable',
      variant: 'Normal',
    },
  },
)

type PeriodicBonusVariants = VariantProps<typeof periodicBonusVariants>
export type PeriodicBonusType = PeriodicBonusVariants['type']

const getIconForCollectButton = (type: PeriodicBonusType): IconNames => {
  return type === 'Claimed' ? 'check' : 'lock-closed'
}

export type PeriodicBonusItemProps = {
  gc?: number
  sc?: number
  index: number
  loading?: boolean
  locked?: boolean
  countdown?: string
  graphic?: string
  onCollectClick: (index: number) => void
} & PeriodicBonusVariants

export const PeriodicBonusItem = ({
  gc,
  sc,
  index,
  loading,
  variant,
  type,
  locked,
  countdown,
  onCollectClick,
  graphic,
}: PeriodicBonusItemProps) => {
  const formatDuration = useDurationFormat()
  const convertedDuration = useMemo(
    () => (countdown ? durationToDate(countdown + '') : undefined),
    [countdown],
  )
  const duration = useCountdownDuration(convertedDuration)

  const countdownFinished = Object.keys(duration || {}).length === 0

  // from BE standpoint when we have a counter, it is a `Pending` state,
  // but from the design perspective it should use the same styles as `Claimable`
  type = countdown ? 'Claimable' : type

  locked =
    locked || !countdownFinished || type == 'Claimed' || type == 'Pending'

  return (
    <div
      className={cn(periodicBonusVariants({ variant, type }), 'shrink-0')}
      data-testid="periodic-bonus-item"
    >
      <strong className="font-display">
        <Trans>Day {index}</Trans>
      </strong>
      <PeriodicBonusImage graphic={graphic} />
      <div className="flex-1">
        {gc && (
          <p data-testid="periodic-bonus-item-gold-coin">
            <strong>
              <Trans>GC</Trans>
            </strong>{' '}
            {GcFormatter.format(gc)}
          </p>
        )}
        {sc && (
          <p data-testid="periodic-bonus-item-sweeps-coin">
            <strong>
              <Trans>{gc ? '+ ' : ' '}SC</Trans>
            </strong>{' '}
            {ScFormatter.format(sc)}
          </p>
        )}
      </div>
      <Button
        disabled={locked}
        className="mt-1 h-10 w-full px-0"
        loading={loading}
        onClick={() => onCollectClick(index)}
        data-testid="periodic-bonus-item-button"
      >
        {locked ? (
          <>
            <CustomIcon
              size="sm"
              name={getIconForCollectButton(type)}
              fill="fill-content-disabled"
            />
            {!countdownFinished && (
              <span className="ml-0.5 w-[70px] whitespace-nowrap">
                {formatDuration(duration!)}
              </span>
            )}
          </>
        ) : (
          <Trans>Collect</Trans>
        )}
      </Button>
    </div>
  )
}
