import { forwardRef } from 'react'
import * as PopoverPrimitive from '@radix-ui/react-popover'
import { cn } from '@vgw/tailwind-merger'

type ContentProps = PopoverPrimitive.PopoverContentProps & {}

const Content = forwardRef<HTMLDivElement, ContentProps>(
  ({ children, className, ...props }: ContentProps, ref) => {
    return (
      <PopoverPrimitive.Content
        className={cn(
          [
            // TODO: we should create z-index layering for the whole app
            'z-50',
            'border',
            'border-gray-300',
            'bg-white',
            'shadow-lg',
            'inline-flex',
            'flex-col',
            'items-start',
            'gap-4',
            'rounded-md',
            'will-change-[transform,opacity]',
            'data-[state=open]:data-[side=bottom]:animate-slide-up-and-fade',
            'data-[state=open]:data-[side=left]:animate-slide-right-and-fade',
            'data-[state=open]:data-[side=top]:animate-slide-down-and-fade"',
            'data-[state=open]:data-[side=right]:animate-slide-left-and-fade',
          ],
          className,
        )}
        sideOffset={5}
        ref={ref}
        {...props}
      >
        {children}
      </PopoverPrimitive.Content>
    )
  },
)

Content.displayName = 'PopoverContent'

export { Content }
