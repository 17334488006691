import { NotVerifiedInGrace } from '@/features/periodic-bonus/grace-period/not-verified-in-grace'
import { PendingVerificationInGrace } from '@/features/periodic-bonus/grace-period/pending-verification-in-grace'
import { PendingVerificationAndLocked } from '@/features/periodic-bonus/grace-period/pending-verification-and-locked'
import { NotVerifiedAndLocked } from '@/features/periodic-bonus/grace-period/not-verified-and-locked'

interface GracePeriodProps {
  status: string
  onVerifyNow: () => void
  gracePeriodEnd: string | undefined
}

export const GracePeriod = ({
  status,
  onVerifyNow,
  gracePeriodEnd,
}: GracePeriodProps) => {
  const remainingDays = gracePeriodEnd ? parseInt(gracePeriodEnd) : undefined
  switch (status) {
    case 'Required':
      if (remainingDays && remainingDays > 0)
        return (
          <NotVerifiedInGrace
            remainingDays={remainingDays}
            onClick={onVerifyNow}
          />
        )

      return <NotVerifiedAndLocked onClick={onVerifyNow} />
    case 'PendingVerification':
      if (remainingDays && remainingDays > 0)
        return <PendingVerificationInGrace remainingDays={remainingDays} />

      return <PendingVerificationAndLocked />
    default:
      return null
  }
}
