import { ImageHeader } from './image-header'
import { TextHeader } from './text-header'
import { SpecialOffer, SpecialOfferItems } from './special-offer-items'
import { Pills } from './pills'
import { SpecialOfferItemsSetWrapper } from './special-offer-items-set-wrapper'

type SpecialOfferItemsSetProps = {
  name: string
  image?: string
  validUntil?: Date
  claim: {
    limit?: number
    count: number
  }
  items: SpecialOffer[]
  onItemClick: (id: string) => void
}

const SpecialOfferItemsSet = ({
  name,
  image,
  validUntil,
  claim,
  items,
  onItemClick,
}: SpecialOfferItemsSetProps) => {
  return (
    <SpecialOfferItemsSetWrapper>
      {image ? (
        <ImageHeader
          image={image}
          name={name}
          renderPills={() => <Pills claim={claim} validUntil={validUntil} />}
        />
      ) : (
        <TextHeader
          name={name}
          renderPills={() => <Pills claim={claim} validUntil={validUntil} />}
        />
      )}
      <SpecialOfferItems
        items={items}
        onItemClick={onItemClick}
        claim={claim}
        validUntil={validUntil}
      />
    </SpecialOfferItemsSetWrapper>
  )
}

export { SpecialOfferItemsSet }
