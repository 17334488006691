import { useMutation, useQueryClient } from '@tanstack/react-query'
import { getBrandConfig } from '@/config/config'
import {
  addGameToFavoritesMutation,
  deleteGameFromFavorites1Mutation,
} from '@/apis/gap-gallery-user-preferences'
import { useGameInfoQueryOptions } from '@/features/game-api/use-game-info-query'
import { useFavoriteCategoryQueryOptions } from '@/pages/home/use-favorite-category-query'

function useToggleGameFavoriteMutation(gameId: string | null) {
  const config = getBrandConfig()
  const queryClient = useQueryClient()

  const gameInfoQueryKey = useGameInfoQueryOptions(gameId).queryKey
  const favoriteCategoryQueryKey = useFavoriteCategoryQueryOptions().queryKey

  const onSettled = async () => {
    queryClient.invalidateQueries({
      queryKey: favoriteCategoryQueryKey,
    })
    queryClient.invalidateQueries({
      queryKey: gameInfoQueryKey,
    })
  }

  const onMutate = async () => {
    await Promise.all([
      queryClient.cancelQueries({
        queryKey: gameInfoQueryKey,
      }),
      queryClient.cancelQueries({
        queryKey: favoriteCategoryQueryKey,
      }),
    ])

    const prevGameInfoData = queryClient.getQueryData(gameInfoQueryKey)

    queryClient.setQueryData(gameInfoQueryKey, (prevQuery) => {
      if (!prevQuery) {
        return prevQuery
      }
      return {
        ...prevQuery,
        isFavorite: !prevQuery.isFavorite,
      }
    })

    return { prevGameInfoData }
  }

  const { mutate: deleteGameFromFavoritesMutate } = useMutation({
    ...deleteGameFromFavorites1Mutation({
      query: {},
      throwOnError: true,
    }),
    onSettled,
    onMutate,
    onError: (_e, _v, context) =>
      queryClient.setQueryData(gameInfoQueryKey, context?.prevGameInfoData),
  })
  const { mutate: addGameToFavoritesMutate } = useMutation({
    ...addGameToFavoritesMutation({
      query: {},
      throwOnError: true,
    }),
    onSettled,
    onMutate,
    onError: (_e, _v, context) =>
      queryClient.setQueryData(gameInfoQueryKey, context?.prevGameInfoData),
  })

  const toggleFavoriteMutation = (gameId: string, isFavorite: boolean) => {
    if (isFavorite) {
      deleteGameFromFavoritesMutate({
        headers: {
          Authorization: null,
        },
        path: { brandCode: config.code, gameId },
      })
    } else {
      addGameToFavoritesMutate({
        headers: {
          Authorization: null,
        },
        path: { brandCode: config.code, gameId },
      })
    }
  }

  return {
    toggleFavoriteMutation,
  }
}

export { useToggleGameFavoriteMutation }
