import * as ToastPrimitive from '@radix-ui/react-toast'
import { cn } from '@vgw/tailwind-merger'
import { ComponentProps } from 'react'

type ToastDescriptionProps = ComponentProps<typeof ToastPrimitive.Description>

const ToastDescription = ({ className, ...props }: ToastDescriptionProps) => {
  return (
    <ToastPrimitive.Description
      {...props}
      className={cn('leading-6', className)}
    />
  )
}

export { ToastDescription }
