import {
  Drawer,
  DrawerBody,
  DrawerClose,
  DrawerContent,
} from '@vgw/multibrand-fe-drawer'
import { CustomIcon } from '@vgw/multibrand-fe-icon'
import {
  PurchaseFiltersContent,
  PurchaseFiltersValues,
} from './purchase-filters-content'
import { Trans } from '@lingui/react/macro'
import { useState } from 'react'
import { Button } from '@vgw/multibrand-fe-button'
import { cn } from '@vgw/tailwind-merger'

export type PurchaseFiltersProps = {
  filters: PurchaseFiltersValues | null
  onShowAllResults: (data: PurchaseFiltersValues | null) => void
}

export const PurchaseFilters = ({
  filters,
  onShowAllResults,
}: PurchaseFiltersProps) => {
  const [isDrawerOpen, setDrawerOpen] = useState(false)

  const onShowResults = (data: PurchaseFiltersValues | null) => {
    setDrawerOpen(false)
    onShowAllResults(data)
  }

  const applyFiltersToButton = !!filters

  return (
    <Drawer
      open={isDrawerOpen}
      trigger={
        <Button
          size="sm"
          variant={applyFiltersToButton ? 'primary' : 'ghost'}
          data-testid="purchase-filters-button"
          className={cn({
            'bg-button-surface-brand-primary-active': applyFiltersToButton,
          })}
          onClick={() => setDrawerOpen(true)}
        >
          <CustomIcon
            fill={applyFiltersToButton ? 'fill-white' : 'fill-content-base'}
            name="mixer-horizontal"
            className="-mt-[1px]"
            wrapperClassName="h-3.5 w-3.5"
          />
          <Trans>Filter {applyFiltersToButton ? '(1)' : null}</Trans>
        </Button>
      }
    >
      <DrawerContent
        position="bottom"
        className="max-h-[80%] bg-content-inverse"
      >
        <DrawerClose onClick={() => setDrawerOpen(false)} />
        <DrawerBody>
          <PurchaseFiltersContent
            filters={filters}
            onShowAllResults={onShowResults}
          />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}
