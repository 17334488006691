import goldCoinsPackageSmImageUrl from './assets/goldcoins-package-sm.png'
import goldCoinsPackageSmX2ImageUrl from './assets/goldcoins-package-sm-x2.png'
import goldCoinsPackageMdImageUrl from './assets/goldcoins-package-md.png'
import goldCoinsPackageMdX2ImageUrl from './assets/goldcoins-package-md-x2.png'
import goldCoinsPackageLgImageUrl from './assets/goldcoins-package-lg.png'
import goldCoinsPackageLgX2ImageUrl from './assets/goldcoins-package-lg-x2.png'

interface PeriodicBonusImageProps {
  graphic?: string
}

const getGraphicImage = (graphic?: string) => {
  switch (graphic) {
    case 'goldcoins-package-md':
      return {
        regular: goldCoinsPackageMdImageUrl,
        large: goldCoinsPackageMdX2ImageUrl,
      }
    case 'goldcoins-package-lg':
      return {
        regular: goldCoinsPackageLgImageUrl,
        large: goldCoinsPackageLgX2ImageUrl,
      }
    default:
      return {
        regular: goldCoinsPackageSmImageUrl,
        large: goldCoinsPackageSmX2ImageUrl,
      }
  }
}

export const PeriodicBonusImage = ({ graphic }: PeriodicBonusImageProps) => {
  const { regular, large } = getGraphicImage(graphic)

  return (
    <img
      data-testid="periodic-bonus-image"
      className="mx-auto my-0.5"
      width={42}
      height={45}
      src={regular}
      srcSet={`${regular} 42w, ${large} 84w`}
      alt=""
    />
  )
}
