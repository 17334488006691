const language = 'en-US'

export const priceFormatter = (currency: string = 'USD') =>
  new Intl.NumberFormat(language, {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

export const GcFormatter = new Intl.NumberFormat(language)

export const ScFormatter = new Intl.NumberFormat(language, {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})
