import { ReactNode } from 'react'
import * as PopoverPrimitive from '@radix-ui/react-popover'

export type PopoverProps = PopoverPrimitive.PopoverProps & {
  trigger?: ReactNode
}

const Popover = ({ children, trigger, ...props }: PopoverProps) => {
  return (
    <PopoverPrimitive.Root {...props}>
      {trigger && (
        <PopoverPrimitive.Trigger asChild>{trigger}</PopoverPrimitive.Trigger>
      )}
      <PopoverPrimitive.Portal>{children}</PopoverPrimitive.Portal>
    </PopoverPrimitive.Root>
  )
}

Popover.displayName = 'Popover'

export { Popover }
