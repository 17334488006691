import { IconButton } from '@vgw/multibrand-fe-icon-button'

export interface PlayForFreeContentItemProps {
  iconUrl: string
  title: string
  description: string
  srcSet: string
}

export const PlayForFreeContentItem = ({
  iconUrl,
  srcSet,
  title,
  description,
}: PlayForFreeContentItemProps) => (
  <div className="flex items-center gap-1.5 rounded-md bg-surface-muted p-2">
    <div>
      <img src={iconUrl} width="55" height="55" srcSet={srcSet} alt="" />
    </div>
    <div className="flex flex-col gap-0.5">
      <p className="text-base font-bold leading-6">{title}</p>
      <p className="font-body text-base font-normal leading-6">{description}</p>
    </div>
    <div className="ml-auto">
      <IconButton size="sm" icon="arrow-right" variant="primary" />
    </div>
  </div>
)
