import { MouseEvent } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { cva, VariantProps } from 'class-variance-authority'
import { MessageDescriptor } from '@lingui/core'
import { useLingui } from '@lingui/react/macro'
import * as NavigationMenu from '@radix-ui/react-navigation-menu'
import { cn } from '@vgw/tailwind-merger'
import { CustomIcon, IconNames } from '@vgw/multibrand-fe-icon'
import { MobileMenuValues } from './types'

const mobileMenuNavbarIconVariants = cva(['relative', 'px-2', 'py-0.5'], {
  variants: {
    variant: {
      default: [],
      withBackground: [
        'rounded-full',
        'group-[.active]:bg-navigation-surface-active',
      ],
    },
  },
  defaultVariants: {
    variant: 'default',
  },
})

export interface MobileMenuNavbarItemProps
  extends VariantProps<typeof mobileMenuNavbarIconVariants>,
    NavigationMenu.NavigationMenuItemProps {
  value: MobileMenuValues
  label: MessageDescriptor
  icon: [IconNames, IconNames]
  path?: string
  hasNotification?: boolean
}

export const MobileMenuNavbarItem = ({
  label,
  icon,
  path,
  variant,
  hasNotification = false,
  ...props
}: MobileMenuNavbarItemProps) => {
  const location = useLocation()

  const isActive = location.pathname === path

  const handleClick = (e: MouseEvent<HTMLAnchorElement>) => {
    if (isActive) {
      e.preventDefault()
    }
  }

  return (
    <NavigationMenu.Item {...props}>
      {path !== undefined ? (
        <NavigationMenu.Link
          asChild
          className="group flex flex-col items-center gap-0.5"
        >
          <NavLink to={path} onClick={handleClick}>
            <MobileMenuNavbarItemContent
              label={label}
              icon={icon}
              path={path}
              variant={variant}
              hasNotification={hasNotification}
            />
          </NavLink>
        </NavigationMenu.Link>
      ) : (
        <NavigationMenu.Trigger
          onPointerMove={(e) => e.preventDefault()}
          onPointerLeave={(e) => e.preventDefault()}
          className="group flex flex-col items-center gap-0.5"
        >
          <MobileMenuNavbarItemContent
            label={label}
            icon={icon}
            path={path}
            variant={variant}
            hasNotification={hasNotification}
          />
        </NavigationMenu.Trigger>
      )}
    </NavigationMenu.Item>
  )
}

type MobileMenuNavbarItemContentProps = Pick<
  MobileMenuNavbarItemProps,
  'variant' | 'label' | 'icon' | 'path' | 'hasNotification'
>

const MobileMenuNavbarItemContent = ({
  variant,
  hasNotification,
  icon,
  label,
  path,
}: MobileMenuNavbarItemContentProps) => {
  const { t } = useLingui()
  const location = useLocation()

  const isActive = location.pathname === path

  return (
    <>
      <div
        className={cn(
          mobileMenuNavbarIconVariants({ variant }),
          hasNotification && [
            'before:absolute',
            'before:right-1',
            'before:top-1.5',
            'before:block',
            'before:h-1.5',
            'before:w-1.5',
            'before:rounded-full',
            'before:bg-status-error',
          ],
        )}
      >
        <CustomIcon
          name={variant === 'default' && isActive ? icon[1] : icon[0]}
          size="lg"
          fill="fill-navigation-content-base"
          className="group-[.active]:fill-navigation-content-active"
        />
      </div>
      <span className="text-xs font-medium text-navigation-content-base group-[.active]:text-navigation-content-active">
        {t(label)}
      </span>
    </>
  )
}
