import { Close as PrimitiveClose } from '@radix-ui/react-dialog'
import { useLingui } from '@lingui/react/macro'
import { IconButton } from '@vgw/multibrand-fe-icon-button'
import { ComponentProps } from 'react'

const Close = ({ ...props }: ComponentProps<typeof PrimitiveClose>) => {
  const { t } = useLingui()

  return (
    <PrimitiveClose asChild {...props}>
      <IconButton
        className="absolute right-1 top-1"
        aria-label={t`Close`}
        icon="cross-1"
        variant="ghost"
      />
    </PrimitiveClose>
  )
}

Close.displayName = 'DialogClose'

export { Close }
