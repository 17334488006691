import { ReactNode } from 'react'
import {
  DialogProps as PrimitiveDialogProps,
  Portal,
  Root,
  Trigger,
} from '@radix-ui/react-dialog'

export interface DrawerProps extends PrimitiveDialogProps {
  children: ReactNode
  trigger?: ReactNode
}

export const Drawer = ({ children, trigger, ...props }: DrawerProps) => {
  return (
    <Root data-testid="drawer-root" {...props}>
      {trigger && <Trigger asChild>{trigger}</Trigger>}
      <Portal>{children}</Portal>
    </Root>
  )
}

Drawer.displayName = 'Drawer'
