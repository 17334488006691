import { UserHeader } from '../user-header/user-header'
import { cn } from '@vgw/tailwind-merger'
import { Link } from 'react-router-dom'
import { HeaderProps } from '../types'
import Logo from '../logo'
import { SubMenuNavigation } from '../sub-menu-navigation'
import { MobileMenuNavbar } from '../mobile-menu-navbar/mobile-menu-navbar'

export const OutOfGameHeader = ({
  user: { userName, logout, userEmail },
  className,
  coinTypeInfo,
}: HeaderProps) => {
  return (
    <>
      <header
        className={cn(
          'z-20 flex w-full flex-col items-center bg-navigation-surface shadow-header md:bg-surface-base', // TODO: we should create z-index layering for the whole app
          className,
        )}
        data-testid="page-header"
      >
        <div className="flex w-full max-w-screen-2xl items-center justify-between gap-2 px-2 py-2.5 sm:px-4 md:px-6">
          <Link data-testid="logo" to="/">
            <Logo />
          </Link>
          <UserHeader
            logout={logout}
            username={userName}
            userEmail={userEmail}
            coinTypeInfo={coinTypeInfo}
          />
        </div>
      </header>
      <SubMenuNavigation />
      <MobileMenuNavbar />
    </>
  )
}
