import { useQuery } from '@tanstack/react-query'
import { offersOptions, OffersResponse } from '@/apis/pam-store-api'

export type StoreData = OffersResponse

type StoreQueryProps = {
  enabled?: boolean
}

function useStoreQuery({ enabled = true }: StoreQueryProps) {
  const options = offersOptions()

  const { data, refetch, isLoading } = useQuery({ ...options, enabled })

  return {
    data,
    refetch,
    isLoading,
  }
}

export { useStoreQuery }
