/* eslint-disable lingui/no-unlocalized-strings */
import animate from 'tailwindcss-animate'
import containerQueries from '@tailwindcss/container-queries'
import { screens } from './screens'

export const borderWidth = {
  'button-border-width-brand-primary':
    'var(--button-border-width-brand-primary)',
  'button-border-width-brand-secondary':
    'var(--button-border-width-brand-secondary)',
  'button-border-width-outline': 'var(--button-border-width-outline)',
  'button-border-width-social': 'var(--button-border-width-social)',
}

export const borderRadius = {
  'radius-button-brand-primary': 'var(--radius-button-brand-primary)',
  'radius-store-tab-surface': 'var(--radius-store-tab-surface)',
  'radius-store-tab-active': 'var(--radius-store-tab-active)',
  'radius-button-brand-secondary': 'var(--radius-button-brand-secondary)',
  'radius-base': 'var(--radius-base)',
  'radius-icon-button': 'var(--radius-icon-button)',
  'radius-form': 'var(--radius-form)',
  'navigation-radius': 'var(--navigation-radius)',
  'navigation-floating-radius': 'var(--navigation-floating-radius)',
  'radius-jackpot-ticker': 'var(--radius-jackpot-ticker)',
}

export const preset: Record<string, unknown> = {
  theme: {
    extend: {
      colors: {
        'brand-primary': 'var(--brand-primary)',
        'brand-primary-shade': 'var(--brand-primary-shade)',
        'brand-secondary': 'var(--brand-secondary)',
        'brand-secondary-shade': 'var(--brand-secondary-shade)',

        'category-title': 'var(--category-title)',

        'content-base': 'var(--content-base)',
        'content-disabled': 'var(--content-disabled)',
        'content-inverse': 'var(--content-inverse)',
        'content-muted': 'var(--content-muted)',
        'content-placeholder': 'var(--content-placeholder)',

        current: 'currentColor',

        disabled: 'var(--disabled)',

        logo: 'var(--logo)',

        skeleton: 'var(--skeleton)',

        'link-primary': 'var(--link-primary)',
        'link-primary-active': 'var(--link-primary-active)',
        'link-primary-hover': 'var(--link-primary-hover)',

        'link-secondary': 'var(--link-secondary)',
        'link-secondary-active': 'var(--link-secondary-active)',
        'link-secondary-hover': 'var(--link-secondary-hover)',

        'border-base': 'var(--border-base)',
        'border-disabled': 'var(--border-disabled)',
        'border-muted': 'var(--border-muted)',

        'button-border-focus': 'var(--button-border-focus)',
        'button-border-outline': 'var(--button-border-outline)',
        'button-border-brand-primary': 'var(--button-border-brand-primary)',
        'button-border-brand-primary-active':
          'var(--button-border-brand-primary-active)',
        'button-border-brand-primary-hover':
          'var(--button-border-brand-primary-hover)',
        'button-border-brand-secondary': 'var(--button-border-brand-secondary)',
        'button-border-brand-secondary-active':
          'var(--button-border-brand-secondary-active)',
        'button-border-brand-secondary-hover':
          'var(--button-border-brand-secondary-hover)',
        'button-border-social': 'var(--button-border-social)',
        'button-border-social-active': 'var(--button-border-social-active)',
        'button-border-social-hover': 'var(--button-border-social-hover)',
        'button-content-brand-primary': 'var(--button-content-brand-primary)',
        'button-content-brand-secondary':
          'var(--button-content-brand-secondary)',
        'button-content-ghost': 'var(--button-content-ghost)',
        'button-content-outline': 'var(--button-content-outline)',
        'button-icon-brand-primary': 'var(--button-icon-brand-primary)',
        'button-icon-brand-secondary': 'var(--button-icon-brand-secondary)',
        'button-icon-ghost': 'var(--button-icon-ghost)',
        'button-icon-outline': 'var(--button-icon-outline)',
        'button-surface-brand-primary': 'var(--button-surface-brand-primary)',
        'button-surface-brand-secondary':
          'var(--button-surface-brand-secondary)',
        'button-surface-brand-primary-active':
          'var(--button-surface-brand-primary-active)',
        'button-surface-brand-primary-hover':
          'var(--button-surface-brand-primary-hover)',
        'button-surface-brand-secondary-active':
          'var(--button-surface-brand-secondary-active)',
        'button-surface-brand-secondary-hover':
          'var(--button-surface-brand-secondary-hover)',
        'button-surface-ghost-active': 'var(--button-surface-ghost-active)',
        'button-surface-ghost-hover': 'var(--button-surface-ghost-hover)',
        'button-surface-social': 'var(--button-surface-social)',
        'button-surface-social-active': 'var(--button-surface-social-active)',
        'button-surface-social-hover': 'var(--button-surface-social-hover)',

        'accordion-button-content': 'var(--accordion-button-content)',
        'accordion-button-surface-default':
          'var(--accordion-button-surface-default)',
        'accordion-button-surface-hover':
          'var(--accordion-button-surface-hover)',
        'accordion-button-surface-open-default':
          'var(--accordion-button-surface-open-default)',
        'accordion-button-surface-pressed':
          'var(--accordion-button-surface-pressed)',

        'form-checkbox-checked': 'var(--form-checkbox-checked)',
        'form-checkbox-checked-active': 'var(--form-checkbox-checked-active)',
        'form-checkbox-checked-hover': 'var(--form-checkbox-checked-hover)',
        'form-checkbox-content-base': 'var(--form-checkbox-content-base)',
        'form-checkbox-unchecked': 'var(--form-checkbox-unchecked)',
        'form-checkbox-border-unchecked':
          'var(--form-checkbox-border-unchecked)',
        'form-checkbox-border-unchecked-active':
          'var(--form-checkbox-border-unchecked-active)',
        'form-checkbox-border-unchecked-hover':
          'var(--form-checkbox-border-unchecked-hover)',
        'form-input-surface': 'var(--form-input-surface)',
        'form-input-surface-secondary': 'var(--form-input-surface-secondary)',
        'form-input-border-active': 'var(--form-input-border-active)',
        'form-input-border-hover': 'var(--form-input-border-hover)',
        'form-input-content-base': 'var(--form-input-content-base)',
        'form-input-content-label-active':
          'var(--form-input-content-label-active)',
        'form-input-content-helper': 'var(--form-input-content-helper)',
        'form-switch-checked': 'var(--form-switch-checked)',
        'form-switch-checked-active': 'var(--form-switch-checked-active)',
        'form-switch-checked-hover': 'var(--form-switch-checked-hover)',
        'form-switch-toggle': 'var(--form-switch-toggle)',
        'form-switch-unchecked': 'var(--form-switch-unchecked)',
        'form-switch-border-checked': 'var(--form-switch-border-checked)',
        'form-switch-border-checked-active':
          'var(--form-switch-border-checked-active)',
        'form-switch-border-checked-hover':
          'var(--form-switch-border-checked-hover)',
        'form-switch-border-unchecked': 'var(--form-switch-border-unchecked)',
        'form-switch-border-unchecked-active':
          'var(--form-switch-border-unchecked-active)',
        'form-switch-border-unchecked-hover':
          'var(--form-switch-border-unchecked-hover)',

        'gold-coins-base': 'var(--gold-coins-base)',
        'gold-coins-surface-active': 'var(--gold-coins-surface-active)',

        'navigation-surface-active': 'var(--navigation-surface-active)',
        'navigation-content-active': 'var(--navigation-content-active)',
        'navigation-content-base': 'var(--navigation-content-base)',
        'navigation-surface': 'var(--navigation-surface)',

        'overlay-base': 'var(--overlay-base)',
        'overlay-dark': 'var(--overlay-dark)',

        'status-default': 'var(--status-default)',
        'status-default-muted': 'var(--status-default-muted)',
        'status-error': 'var(--status-error)',
        'status-error-muted': 'var(--status-error-muted)',
        'status-info': 'var(--status-info)',
        'status-info-muted': 'var(--status-info-muted)',
        'status-success': 'var(--status-success)',
        'status-success-muted': 'var(--status-success-muted)',
        'status-warning': 'var(--status-warning)',
        'status-warning-muted': 'var(--status-warning-muted)',
        'status-muted-surface-content': 'var(--status-muted-surface-content)',

        'surface-base': 'var(--surface-base)',
        'surface-secondary': 'var(--surface-secondary)',
        'surface-muted': 'var(--surface-muted)',
        'surface-neutral': 'var(--surface-neutral)',
        'surface-tertiary': 'var(--surface-tertiary)',

        'sweeps-coins-base': 'var(--sweeps-coins-base)',
        'sweeps-coins-surface-active': 'var(--sweeps-coins-surface-active)',

        'tooltip-border': 'var(--tooltip-border)',
        'tooltip-content': 'var(--tooltip-content)',
        'tooltip-surface': 'var(--tooltip-surface)',

        'daily-bonus-border-card-selected':
          'var(--daily-bonus-border-card-selected)',
        'daily-bonus-surface-base': 'var(--daily-bonus-surface-base)',
        'daily-bonus-surface-card-base': 'var(--daily-bonus-surface-card-base)',
        'daily-bonus-surface-card-selected':
          'var(--daily-bonus-surface-card-selected)',

        'jackpot-ticker-content-base': 'var(--jackpot-ticker-content-base)',
        'jackpot-ticker-content-grand': 'var(--jackpot-ticker-content-grand)',
        'jackpot-ticker-content-major': 'var(--jackpot-ticker-content-major)',
        'jackpot-ticker-content-minor': 'var(--jackpot-ticker-content-minor)',
        'jackpot-ticker-surface-base': 'var(--jackpot-ticker-surface-base)',

        'jackpot-ticker-border-grand-0': 'var(--jackpot-ticker-border-grand-0)',
        'jackpot-ticker-border-grand-100':
          'var(--jackpot-ticker-border-grand-100)',
        'jackpot-ticker-border-major-0': 'var(--jackpot-ticker-border-major-0)',
        'jackpot-ticker-border-major-100':
          'var(--jackpot-ticker-border-major-100)',
        'jackpot-ticker-border-minor-0': 'var(--jackpot-ticker-border-minor-0)',
        'jackpot-ticker-border-minor-100':
          'var(--jackpot-ticker-border-minor-100)',

        'side-menu-button-active': 'var(--side-menu-button-active)',
        'side-menu-button-default': 'var(--side-menu-button-default)',
        'side-menu-button-hover': 'var(--side-menu-button-hover)',

        'loading-animation': 'var(--loading-animation)',

        'game-card-badge-content-op-1': 'var(--game-card-badge-content-op-1)',
        'game-card-badge-content-op-2': 'var(--game-card-badge-content-op-2)',
        'game-card-badge-content-op-3': 'var(--game-card-badge-content-op-3)',
        'game-card-badge-content-op-4': 'var(--game-card-badge-content-op-4)',
        'game-card-badge-content-op-5': 'var(--game-card-badge-content-op-5)',
        'game-card-badge-surface-op-1': 'var(--game-card-badge-surface-op-1)',
        'game-card-badge-surface-op-2': 'var(--game-card-badge-surface-op-2)',
        'game-card-badge-surface-op-3': 'var(--game-card-badge-surface-op-3)',
        'game-card-badge-surface-op-4': 'var(--game-card-badge-surface-op-4)',
        'game-card-badge-surface-op-5': 'var(--game-card-badge-surface-op-5)',
        'game-card-badge-surface-quest': 'var(--game-card-badge-surface-quest)',

        'store-free-sweeps-content': 'var(--store-free-sweeps-content)',
        'store-free-sweeps-surface': 'var(--store-free-sweeps-surface)',
        'store-tab-content': 'var(--store-tab-content)',
        'store-tab-content-active': 'var(--store-tab-content-active)',
        'store-tab-surface': 'var(--store-tab-surface)',
        'store-tab-surface-active': 'var(--store-tab-surface-active)',
        'store-promotional-area-surface':
          'var(--store-promotional-area-surface)',
        'store-promotional-area-border': 'var(--store-promotional-area-border)',

        transparent: 'transparent',

        'black-alpha': {
          50: 'var(--black-alpha-50)',
          100: 'var(--black-alpha-100)',
          200: 'var(--black-alpha-200)',
          300: 'var(--black-alpha-300)',
          400: 'var(--black-alpha-400)',
          500: 'var(--black-alpha-500)',
          600: 'var(--black-alpha-600)',
          700: 'var(--black-alpha-700)',
          800: 'var(--black-alpha-800)',
          900: 'var(--black-alpha-900)',
          1000: 'var(--black-alpha-1000)',
        },
        'white-alpha': {
          50: 'var(--white-alpha-50)',
          100: 'var(--white-alpha-100)',
          200: 'var(--white-alpha-200)',
          300: 'var(--white-alpha-300)',
          400: 'var(--white-alpha-400)',
          500: 'var(--white-alpha-500)',
          600: 'var(--white-alpha-600)',
          700: 'var(--white-alpha-700)',
          800: 'var(--white-alpha-800)',
          900: 'var(--white-alpha-900)',
          1000: 'var(--white-alpha-1000)',
        },
        blue: {
          50: 'var(--blue-50)',
          100: 'var(--blue-100)',
          200: 'var(--blue-200)',
          300: 'var(--blue-300)',
          400: 'var(--blue-400)',
          500: 'var(--blue-500)',
          600: 'var(--blue-600)',
          700: 'var(--blue-700)',
          800: 'var(--blue-800)',
          900: 'var(--blue-900)',
          1000: 'var(--blue-1000)',
        },
        'blue-gray': {
          50: 'var(--blue-gray-50)',
          100: 'var(--blue-gray-100)',
          200: 'var(--blue-gray-200)',
          300: 'var(--blue-gray-300)',
          400: 'var(--blue-gray-400)',
          500: 'var(--blue-gray-500)',
          600: 'var(--blue-gray-600)',
          700: 'var(--blue-gray-700)',
          800: 'var(--blue-gray-800)',
          900: 'var(--blue-gray-900)',
          1000: 'var(--blue-gray-1000)',
        },
        coral: {
          50: 'var(--coral-50)',
          100: 'var(--coral-100)',
          200: 'var(--coral-200)',
          300: 'var(--coral-300)',
          400: 'var(--coral-400)',
          500: 'var(--coral-500)',
          600: 'var(--coral-600)',
          700: 'var(--coral-700)',
          800: 'var(--coral-800)',
          900: 'var(--coral-900)',
          1000: 'var(--coral-1000)',
        },
        gray: {
          50: 'var(--gray-50)',
          100: 'var(--gray-100)',
          200: 'var(--gray-200)',
          300: 'var(--gray-300)',
          400: 'var(--gray-400)',
          500: 'var(--gray-500)',
          600: 'var(--gray-600)',
          700: 'var(--gray-700)',
          800: 'var(--gray-800)',
          900: 'var(--gray-900)',
          1000: 'var(--gray-1000)',
        },
        green: {
          50: 'var(--green-50)',
          100: 'var(--green-100)',
          200: 'var(--green-200)',
          300: 'var(--green-300)',
          400: 'var(--green-400)',
          500: 'var(--green-500)',
          600: 'var(--green-600)',
          700: 'var(--green-700)',
          800: 'var(--green-800)',
          900: 'var(--green-900)',
          1000: 'var(--green-1000)',
        },
        orange: {
          50: 'var(--orange-50)',
          100: 'var(--orange-100)',
          200: 'var(--orange-200)',
          300: 'var(--orange-300)',
          400: 'var(--orange-400)',
          500: 'var(--orange-500)',
          600: 'var(--orange-600)',
          700: 'var(--orange-700)',
          800: 'var(--orange-800)',
          900: 'var(--orange-900)',
          1000: 'var(--orange-1000)',
        },
        purple: {
          50: 'var(--purple-50)',
          100: 'var(--purple-100)',
          200: 'var(--purple-200)',
          300: 'var(--purple-300)',
          400: 'var(--purple-400)',
          500: 'var(--purple-500)',
          600: 'var(--purple-600)',
          700: 'var(--purple-700)',
          800: 'var(--purple-800)',
          900: 'var(--purple-900)',
          1000: 'var(--purple-1000)',
        },
        red: {
          50: 'var(--red-50)',
          100: 'var(--red-100)',
          200: 'var(--red-200)',
          300: 'var(--red-300)',
          400: 'var(--red-400)',
          500: 'var(--red-500)',
          600: 'var(--red-600)',
          700: 'var(--red-700)',
          800: 'var(--red-800)',
          900: 'var(--red-900)',
          1000: 'var(--red-1000)',
        },
        vanilla: {
          50: 'var(--vanilla-50)',
          100: 'var(--vanilla-100)',
          200: 'var(--vanilla-200)',
          300: 'var(--vanilla-300)',
          400: 'var(--vanilla-400)',
          500: 'var(--vanilla-500)',
          600: 'var(--vanilla-600)',
          700: 'var(--vanilla-700)',
          800: 'var(--vanilla-800)',
          900: 'var(--vanilla-900)',
          1000: 'var(--vanilla-1000)',
        },
        yellow: {
          50: 'var(--yellow-50)',
          100: 'var(--yellow-100)',
          200: 'var(--yellow-200)',
          300: 'var(--yellow-300)',
          400: 'var(--yellow-400)',
          500: 'var(--yellow-500)',
          600: 'var(--yellow-600)',
          700: 'var(--yellow-700)',
          800: 'var(--yellow-800)',
          900: 'var(--yellow-900)',
          1000: 'var(--yellow-1000)',
        },
        cream: {
          50: 'var(--cream-50)',
          100: 'var(--cream-100)',
          200: 'var(--cream-200)',
          300: 'var(--cream-300)',
          400: 'var(--cream-400)',
          500: 'var(--cream-500)',
          600: 'var(--cream-600)',
          700: 'var(--cream-700)',
          800: 'var(--cream-800)',
          900: 'var(--cream-900)',
          1000: 'var(--cream-1000)',
        },
      },
      aspectRatio: {
        picture: '2/3',
      },
      backgroundImage: {
        'jackpot-ticker-gradient':
          'linear-gradient(178.73deg, rgba(0, 0, 0, 0) 1.1%, #000000 53.43%)',
      },
      borderRadius,
      borderWidth,
      screens: screens,
      transitionProperty: {
        width: 'width',
        'border-color': 'border-color',
      },
      transitionDuration: {
        '400': '400ms',
        '600': '600ms',
      },
      transitionTimingFunction: {
        'popover-bezier': 'cubic-bezier(0.16, 1, 0.3, 1)',
        'accordion-bezier': 'cubic-bezier(0.87, 0, 0.13, 1)',
      },
      keyframes: {
        'accordion-down': {
          from: { height: '0' },
          to: { height: 'var(--radix-accordion-content-height)' },
        },
        'accordion-up': {
          from: { height: 'var(--radix-accordion-content-height)' },
          to: { height: '0' },
        },
        'slide-up-and-fade': {
          from: { opacity: '0', transform: 'translateY(2px)' },
          to: { opacity: '1', transform: 'translateY(0)' },
        },
        'slide-right-and-fade': {
          from: { opacity: '0', transform: 'translateX(-2px)' },
          to: { opacity: '1', transform: 'translateX(0)' },
        },
        'slide-down-and-fade': {
          from: { opacity: '0', transform: 'translateY(-2px)' },
          to: { opacity: '1', transform: 'translateY(0)' },
        },
        'slide-left-and-fade': {
          from: { opacity: '0', transform: 'translateX(2px)' },
          to: { opacity: '1', transform: 'translateX(0)' },
        },
        'dialog-overlay-show': {
          from: { opacity: '0' },
          to: { opacity: '1' },
        },
        'dialog-content-show': {
          from: {
            opacity: '0',
            transform: 'translate(-50%, -48%) scale(0.96)',
          },
          to: { opacity: '1', transform: 'translate(-50%, -50%) scale(1)' },
        },
      },
      animation: {
        'accordion-down': 'accordion-down .3s ease-out',
        'accordion-up': 'accordion-up .3s ease-out',
        'slide-up-and-fade':
          'slide-up-and-fade .4s cubic-bezier(0.16, 1, 0.3, 1)',
        'slide-right-and-fade':
          'slide-right-and-fade .4s cubic-bezier(0.16, 1, 0.3, 1)',
        'slide-down-and-fade':
          'slide-down-and-fade .4s cubic-bezier(0.16, 1, 0.3, 1)',
        'slide-left-and-fade':
          "slide-left-and-fade'.4s cubic-bezier(0.16, 1, 0.3, 1)",
        'dialog-overlay-show':
          'dialog-overlay-show 150ms cubic-bezier(0.16, 1, 0.3, 1)',
        'dialog-content-show':
          'dialog-content-show 150ms cubic-bezier(0.16, 1, 0.3, 1)',
      },
      boxShadow: {
        drop: '0px 6px 20px 0px rgba(0, 0, 0, 0.25)',
        tag: '2px 1px 2px 0px rgba(0, 0, 0, 0.35)',
        tabs: '0px 2px 4px 0px #0000001A inset, 0px -2px 4px 0px #0000000D inset;',
        tab: '2px 0px 4px 0px #00000026, -2px 0px 4px 0px #00000026;',
        dialog:
          '0px 16px 36px -20px rgba(255, 255, 255, 0.22), 0px 16px 64px 0px rgba(255, 255, 255, 0.01), 0px 12px 60px 0px rgba(0, 0, 0, 0.05)',
        jackpot: '0px 2px 2px 0px #00000040;',
        header: '0px 1px 2px 0px #0000000F, 0px 1px 3px 0px #0000001A;',
        'mobile-navbar': '0 2px 0px 0px rgba(23, 25, 35, 0.25)',
      },
    },
    fontSize: {
      xs: '0.75rem',
      sm: '0.875rem',
      base: '1rem',
      lg: '1.125rem',
      xl: '1.25rem',
      '2xl': '1.5rem',
      '3xl': '1.75rem',
      '4xl': '2rem',
      '5xl': '2.5rem',
      '6xl': '3.75rem',
    },
    fontFamily: {
      display: ['Montserrat', 'Arial', 'sans-serif'], // Adds a new `font-display` class
      body: ['Roboto', 'Arial', 'sans-serif'],
      anton: ['Anton'],
    },
    brightness: {
      100: '1',
    },
  },
  plugins: [animate, containerQueries],
}
