import { cn } from '@vgw/tailwind-merger'
import { forwardRef, InputHTMLAttributes } from 'react'
import { useFieldStyles } from '../use-field-styles'

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  error?: boolean
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ className, ...props }, ref) => {
    const styles = useFieldStyles()

    return (
      <input
        ref={ref}
        className={cn(styles, className)}
        placeholder=" "
        {...props}
      />
    )
  },
)

Input.displayName = 'TextField.Input'

export { Input }
