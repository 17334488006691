import {
  GetPurchaseHistoryData,
  getPurchaseHistoryInfiniteOptions,
} from '@/apis/pay-purchases'
import { OptionsLegacyParser } from '@hey-api/client-fetch'
import { useInfiniteQuery } from '@tanstack/react-query'

const initialPageParam = { query: { limit: 20, offset: 0 } }

type UsePurchaseHistoryInfiniteQueryProps = {
  enabled?: boolean
} & OptionsLegacyParser<GetPurchaseHistoryData>

export const usePurchaseHistoryInfiniteQuery = ({
  enabled = true,
  ...rest
}: UsePurchaseHistoryInfiniteQueryProps = {}) => {
  const { data, isLoading, isFetchingNextPage, fetchNextPage } =
    useInfiniteQuery({
      ...getPurchaseHistoryInfiniteOptions(rest),
      enabled,
      initialPageParam,
      getNextPageParam: (_, allPages) => {
        return {
          query: {
            limit: 20,
            offset: allPages.flatMap((item) => item.purchases).length,
          },
        }
      },
    })

  return { data, isLoading, isFetchingNextPage, fetchNextPage }
}
