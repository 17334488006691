import { type GameConfig as GameConfigData } from '@/features/game-api/use-game-info-query'
import { Volatility } from './volatility'
import { MinimalPlayAmount } from './minimal-play-amount'
import { PlayerReturn } from './player-return'
import { GameConfigItem } from './game-config-item'

export type GameConfigProps = {
  gameConfig: GameConfigData
}

export const GameConfig = ({ gameConfig }: GameConfigProps) => {
  return (
    <div className="flex gap-4 md:gap-8" data-testid="game-info-game-config">
      {gameConfig.volatility ? (
        <GameConfigItem>
          <Volatility level={gameConfig.volatility} />
        </GameConfigItem>
      ) : null}
      {gameConfig.minPlay ? (
        <GameConfigItem>
          <MinimalPlayAmount minPlay={gameConfig.minPlay} />
        </GameConfigItem>
      ) : null}
      {gameConfig.rtp ? (
        <GameConfigItem>
          <PlayerReturn amount={gameConfig.rtp} />
        </GameConfigItem>
      ) : null}
    </div>
  )
}
