import { env } from '@/config/env-schema'

export interface KeycloakConf {
  url: string
  realm: string
  clientId: string
}

export interface BrazeConf {
  apiKey: string
  baseUrl: string
}

export interface BrandFeatures {
  isSweepsCoinsEnabled: boolean
  isHeroBannerEnabled: boolean
}

export interface UnleashConfig {
  url: string
  clientKey: string
  appName: 'vanilla-client'
}

export interface AmplitudeConfig {
  apiKey: string
  loggingEnabled: boolean
}

export interface Config {
  name: string
  code: string
  gapGalleryViewApiUrl: string
  gapGalleryUserPreferencesApiUrl: string
  gapTickersApiUrl: string
  pamPlayerTokensApiUrl: string
  pamStoreApiUrl: string
  periodicBonusServiceApiUrl: string
  payPurchasesApiUrl: string
  heroBanner: {
    type:
      | 'carouselBasic'
      | 'carouselOverlayed'
      | 'carouselWithLeadingCard'
      | 'simple'
  }
  keycloak: KeycloakConf
  braze: BrazeConf
  features: Omit<BrandFeatures, 'isSweepsCoinsEnabled'>
  unleash?: UnleashConfig
  amplitude: AmplitudeConfig
  helpCenterUrl: string
}

export type PerEnvironmentConfigs = {
  [K in typeof env.CLIENT_VAR__APP_ENV]: Config
}

const BRAND_CONFIG_IMPORTS: {
  [K in typeof env.CLIENT_VAR__BRAND]: () => Promise<{
    default: PerEnvironmentConfigs
  }>
} = {
  vanilla: () => import('@/config/brand/vanilla-config'),
  chocolate: () => import('@/config/brand/chocolate-config'),
}

let perEnvironmentConfig: Config

export const loadBrandConfig = async (): Promise<Config> => {
  if (perEnvironmentConfig) return perEnvironmentConfig

  //Load Brand's config
  const brandConfig = (await BRAND_CONFIG_IMPORTS[env.CLIENT_VAR__BRAND]())
    .default[env.CLIENT_VAR__APP_ENV]
  perEnvironmentConfig = brandConfig

  //Inject the Brand's theme CSS
  loadBrandStyles(env.CLIENT_VAR__BRAND)

  return perEnvironmentConfig
}

const loadBrandStyles = (brand: string) => {
  //Note: I tried to do the import with meta.glob (same as with the config) also template literals to avoid this switch but didn't like, further investigation is required
  switch (brand) {
    case 'chocolate':
      import('@vgw/multibrand-fe-styles/css/themes/chocolate.css')
      break
    default:
      import('@vgw/multibrand-fe-styles/css/themes/vanilla.css')
  }
}

export const getBrandConfig = (): Config => {
  if (!perEnvironmentConfig) {
    throw new Error(
      'Config needs to be loaded before it can be used, did you mean loadBrandConfig?',
    )
  }

  return perEnvironmentConfig
}
