export const BuyGoldCoinsSkeleton = () => {
  return (
    <>
      {[...Array(6)].map((__, index) => (
        <div
          key={index}
          className="h-[66px] w-full animate-pulse rounded-md bg-skeleton"
        />
      ))}
    </>
  )
}
