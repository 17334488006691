import { useEffect } from 'react'
import { Dialog, Overlay, Content } from '@vgw/multibrand-fe-dialog'
import { SelectedItem } from '@/features/store/store'
import { usePurchaseQuery } from '@/features/purchase/use-purchase-query'
import { PurchaseContent } from './purchase-content'

export type PurchaseProps = {
  item: SelectedItem
  isOpen: boolean
  onExitPurchase: () => void
  onPurchaseError: () => void
}

export const Purchase = ({
  item,
  isOpen,
  onExitPurchase,
  onPurchaseError,
}: PurchaseProps) => {
  const { isLoading, error, data } = usePurchaseQuery(item)

  useEffect(() => {
    const handleExitPurchase = (event: MessageEvent) => {
      if (event.data?.type === 'exit-selected') {
        onExitPurchase()
      }
    }

    window.addEventListener('message', handleExitPurchase)
    return () => {
      window.removeEventListener('message', handleExitPurchase)
    }
  }, [onExitPurchase])

  return (
    <Dialog open={isOpen}>
      <Overlay className="z-20" />
      <Content
        className="h-full overflow-hidden border-none md:w-[480px] lg:w-[512px] xl:w-[512px]"
        data-testid="purchase-content"
        isFullScreenOnMobile
      >
        <PurchaseContent
          data={data}
          error={error}
          isLoading={isLoading}
          onPurchaseError={onPurchaseError}
        />
      </Content>
    </Dialog>
  )
}
