import * as ToastPrimitive from '@radix-ui/react-toast'
import { cva, type VariantProps } from 'class-variance-authority'
import { cn } from '@vgw/tailwind-merger'
import { ToastTitle } from './toast-title'
import { ToastDescription } from './toast-description'
import { ToastClose } from './toast-close'
import { ToastAction } from './toast-action'
import { ToastIcon } from './toast-icon'
import { ToastVariant } from './types'
import { ComponentProps, ReactElement, ReactNode } from 'react'

const variants = cva<{
  variant: Record<ToastVariant, string>
}>('relative isolate flex gap-2 rounded-md border-l-2 p-3', {
  variants: {
    variant: {
      neutral: 'bg-status-default border-l-status',
      warning: 'bg-status-warning-muted border-l-status-warning',
      success: 'bg-status-success-muted border-l-status-success',
      error: 'bg-status-error-muted border-l-status-error',
      info: 'bg-status-info-muted border-l-status-info',
    },
  },
  defaultVariants: {
    variant: 'neutral',
  },
})

type ToastProps = {
  icon?: ReactElement
  title: ReactNode
  description?: string
  action?: {
    altText: string
    element: ReactElement
  }
  titleClassName?: string
  close?: boolean
} & Omit<ComponentProps<typeof ToastPrimitive.Root>, 'children' | 'title'> &
  VariantProps<typeof variants>

const Toast = ({
  icon,
  title,
  close = true,
  action,
  variant,
  className,
  description,
  titleClassName,
  ...props
}: ToastProps) => {
  return (
    <ToastPrimitive.Root
      {...props}
      className={cn(variants({ variant, className }))}
    >
      {icon ? <ToastIcon>{icon}</ToastIcon> : null}
      <div className="flex flex-1 flex-wrap items-center gap-3">
        <div className="flex min-w-56 flex-1 flex-col gap-1">
          <ToastTitle className={titleClassName}>{title}</ToastTitle>
          {description ? (
            <ToastDescription>{description}</ToastDescription>
          ) : null}
        </div>
        {action ? (
          <ToastAction asChild altText={action.altText} className="h-fit w-fit">
            {action.element}
          </ToastAction>
        ) : null}
      </div>
      {close ? <ToastClose /> : null}
    </ToastPrimitive.Root>
  )
}

export { Toast }
