import { ReactNode, useEffect, useState } from 'react'
import { format as formatDate } from 'date-fns'
import { TextField } from '@vgw/multibrand-fe-text-field'
import { Calendar, CalendarProps } from '@vgw/multibrand-fe-calendar'
import { IconButton } from '@vgw/multibrand-fe-icon-button'
import * as Popover from '@vgw/multibrand-fe-popover'
import { cn } from '@vgw/tailwind-merger'

type DatePickerProps = {
  htmlFor: string
  label: ReactNode
  accessibleTriggerLabel: string
  autoComplete?: string
  selectedDate?: Date | null
  setSelectedDate: (date?: Date) => void
  format?: string
  error?: boolean
  initialYear?: number
  yearsToRender?: number
  size?: 'sm' | 'md'
} & Omit<CalendarProps, 'mode'>

export const DatePicker = ({
  selectedDate,
  setSelectedDate,
  // eslint-disable-next-line lingui/no-unlocalized-strings
  format = 'MM/dd/yyyy',
  accessibleTriggerLabel,
  label,
  htmlFor,
  autoComplete = 'off',
  error = false,
  initialYear,
  yearsToRender,
  size = 'md',
  ...rest
}: DatePickerProps) => {
  const [value, setValue] = useState(
    selectedDate ? formatDate(selectedDate.toString(), format) : '',
  )

  useEffect(() => {
    setValue(selectedDate ? formatDate(selectedDate.toString(), format) : '')
  }, [selectedDate, format])

  return (
    <div className="relative">
      <TextField error={error} size={size}>
        <TextField.MaskedInput
          data-testid="date-picker-input"
          mask={format?.replace(/[a-zA-Z]/g, '9')}
          value={value}
          onChange={(value) => {
            setValue(value.target.value)
            const timeStamp = Date.parse(value.target.value)

            if (!isNaN(timeStamp)) {
              setSelectedDate(new Date(timeStamp))
            }
          }}
          autoComplete={autoComplete}
          id={htmlFor}
        />
        <TextField.Label htmlFor={htmlFor}>{label}</TextField.Label>
      </TextField>
      <Popover.Root
        trigger={
          <IconButton
            className={cn(
              ['data-[state=open]:bg-button-surface-ghost-hover', 'absolute'],
              {
                'right-1 top-2': size === 'sm',
              },
              {
                'right-1.5 top-2.5': size === 'md',
              },
            )}
            variant="ghost"
            type="button"
            icon="calendar"
            size={size}
            aria-controls="dialog"
            aria-haspopup="dialog"
            aria-label={accessibleTriggerLabel}
          />
        }
      >
        <Popover.Content className="border-none">
          <Calendar
            {...rest}
            mode="single"
            onSelect={(date) => {
              setSelectedDate(date)
              setValue(formatDate(date || '', format))
            }}
            selected={selectedDate || undefined}
            initialYear={initialYear}
            yearsToRender={yearsToRender}
            size={size}
          />
        </Popover.Content>
      </Popover.Root>
    </div>
  )
}
