import * as ToastPrimitive from '@radix-ui/react-toast'
import { cn } from '@vgw/tailwind-merger'
import { ComponentProps } from 'react'

type ToastViewportProps = ComponentProps<typeof ToastPrimitive.Viewport>

const ToastViewport = ({ className, ...props }: ToastViewportProps) => {
  return (
    <ToastPrimitive.Viewport
      {...props}
      className={cn('grid w-full max-w-screen-md gap-2', className)}
    />
  )
}

export { ToastViewport }
