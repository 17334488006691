import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
  checkBonusQueryKey,
  claimBonusMutation,
} from '@/apis/periodic-bonus-service'
import { CLAIMED_COUNT, PENDING_COUNT } from './use-bonus-options-query'

function usePeriodicBonusClaimMutation() {
  const queryClient = useQueryClient()

  const { mutate, isPending } = useMutation({
    ...claimBonusMutation({
      headers: {
        Authorization: null,
      },
      query: {},
    }),
    onSuccess: async (_, variables) => {
      await queryClient.invalidateQueries({
        queryKey: checkBonusQueryKey({
          path: variables.path,
          query: {
            claimedCount: CLAIMED_COUNT,
            pendingCount: PENDING_COUNT,
          },
          headers: { Authorization: null },
        }),
      })
    },
  })

  return { mutate, isPending }
}

export { usePeriodicBonusClaimMutation }
