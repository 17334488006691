import { Link } from 'react-router-dom'
import { Link as MultibrandLink } from '@vgw/multibrand-fe-link'
import { Trans } from '@lingui/react/macro'

interface FooterNavigationProps {
  onCookiesConsentOpen: () => void
}

export const FooterNavigation = ({
  onCookiesConsentOpen,
}: FooterNavigationProps) => {
  return (
    <nav className="grid grid-cols-2 gap-2 md:flex md:flex-wrap md:justify-center md:gap-x-5 lg:flex-row">
      <MultibrandLink size="xs" asChild>
        <Link to="/terms-conditions" className="w-fit underline">
          <Trans>Terms & Conditions</Trans>
        </Link>
      </MultibrandLink>
      <MultibrandLink size="xs" asChild>
        <button onClick={onCookiesConsentOpen} className="text-left">
          <Trans>Your Privacy Choices</Trans>
        </button>
      </MultibrandLink>
      <MultibrandLink size="xs" asChild>
        <Link to="/privacy-policy" className="w-fit underline">
          <Trans>Privacy Policy</Trans>
        </Link>
      </MultibrandLink>
      <MultibrandLink size="xs" asChild>
        <Link to="/responsible-social-gameplay" className="w-fit underline">
          <Trans>Responsible Social Gameplay</Trans>
        </Link>
      </MultibrandLink>
      <MultibrandLink size="xs" asChild>
        <Link to="/postal-request-code" className="w-fit underline">
          <Trans>Postal Request Code</Trans>
        </Link>
      </MultibrandLink>
      <MultibrandLink size="xs" asChild>
        <Link to="/contact-us" className="w-fit underline">
          <Trans>Contact Us</Trans>
        </Link>
      </MultibrandLink>
    </nav>
  )
}
