import { cn } from '@vgw/tailwind-merger'
import { Primitive, PrimitivePropsWithRef } from '@radix-ui/react-primitive'
import { forwardRef } from 'react'
import { cva, VariantProps } from 'class-variance-authority'

const linkVariants = cva(
  ['underline', 'outline-offset-2', 'decoration-solid'],
  {
    variants: {
      variant: {
        primary: [
          'text-link-primary',
          'hover:text-link-primary-hover',
          'active:text-link-primary-active',
        ],
        secondary: [
          'text-link-secondary',
          'hover:text-link-secondary-hover',
          'active:text-link-secondary-active',
        ],
      },
      size: {
        xs: 'text-xs',
        sm: 'text-sm',
        md: 'text-base',
        lg: 'text-lg',
        xl: 'text-xl',
        '2xl': 'text-2xl',
        '3xl': 'text-3xl',
        '4xl': 'text-4xl decoration-2 underline-offset-2',
        '5xl': 'text-5xl decoration-2 underline-offset-2',
        '6xl': 'text-6xl decoration-[3px] underline-offset-4',
      },
      weight: {
        light: 'font-light',
        regular: 'font-normal',
        medium: 'font-medium',
        bold: 'font-bold',
      },
    },
    defaultVariants: {
      variant: 'primary',
      size: 'md',
      weight: 'light',
    },
  },
)

interface LinkProps
  extends PrimitivePropsWithRef<'a'>,
    VariantProps<typeof linkVariants> {}

const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  ({ href, children, className, variant, size, weight, ...props }, ref) => {
    return (
      <Primitive.a
        ref={ref}
        href={href}
        className={cn(
          linkVariants({ variant, size, weight, className }),
          className,
        )}
        {...props}
      >
        {children}
      </Primitive.a>
    )
  },
)

Link.displayName = 'Link'

export { Link }
