import { Chip } from '@vgw/multibrand-fe-chip'
import { Trans } from '@lingui/react/macro'

export type ClaimLimitProps = {
  limit: number
  count: number
}

export const ClaimLimit = ({ limit, count }: ClaimLimitProps) => {
  return (
    <Chip data-testid="claim-limit">
      <Trans>
        Claim Limit {count}/{limit}
      </Trans>
    </Chip>
  )
}
