import { useQuery } from '@tanstack/react-query'
import {
  createPurchaseOptions,
  createSpecialOfferPurchaseOptions,
  PurchaseInitialiseResponse,
} from '@/apis/pam-store-api'
import { SelectedItem } from '@/features/store/store'
import { useLingui } from '@lingui/react/macro'

export type PurchaseData = PurchaseInitialiseResponse

const usePurchaseQueryOptions = (item: SelectedItem) => {
  const payload = {
    body: {
      itemId: item.itemId,
    },
  }

  if (item.type === 'special-offer-item') {
    return createSpecialOfferPurchaseOptions(payload)
  }

  return createPurchaseOptions(payload)
}

function usePurchaseQuery(item: SelectedItem) {
  const { t } = useLingui()
  const options = usePurchaseQueryOptions(item)

  const { data, error, isLoading } = useQuery({
    ...options,
    enabled: !!item.itemId,
  })

  return {
    data,
    error: error ? t`An error has occurred` : undefined,
    isLoading,
  }
}

export { usePurchaseQuery }
