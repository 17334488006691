import { type GameConfig as GameConfigData } from '@/features/game-api/use-game-info-query'
import { Volatility } from './volatility'
import { MinimalPlayAmount } from './minimal-play-amount'
import { CoinType } from '@/selected-coin-type-context'
import { PlayerReturn } from './player-return'
import { GameConfigItem } from './game-config-item'

type GameConfigProps = {
  gameConfig: GameConfigData
  selectedCoinType: CoinType
}

export const GameConfig = ({
  gameConfig,
  selectedCoinType,
}: GameConfigProps) => {
  return (
    <div className="flex gap-4 md:gap-8">
      {gameConfig.volatility ? (
        <GameConfigItem>
          <Volatility level={gameConfig.volatility} />
        </GameConfigItem>
      ) : null}
      {gameConfig.minPlay ? (
        <GameConfigItem>
          <MinimalPlayAmount
            minPlay={gameConfig.minPlay}
            selectedCoinType={selectedCoinType}
          />
        </GameConfigItem>
      ) : null}
      {gameConfig.rtp ? (
        <GameConfigItem>
          <PlayerReturn amount={gameConfig.rtp} />
        </GameConfigItem>
      ) : null}
    </div>
  )
}
