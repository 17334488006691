import { useQuery } from '@tanstack/react-query'
import {
  checkBonusOptions,
  checkBonusQueryKey,
  DefinitionIdWithDuration,
} from '@/apis/periodic-bonus-service'
import { useMemo } from 'react'

export const CLAIMED_COUNT = 1
export const PENDING_COUNT = 7

function useBonusOptionsQuery(definitions: DefinitionIdWithDuration[] = []) {
  const dailyBonus = useMemo(
    () => definitions?.find((item) => item.duration === 'PT24H'),
    [definitions],
  )

  const variables = useMemo(
    () => ({
      path: {
        bonusId: dailyBonus?.bonusId as string,
      },
      query: {
        claimedCount: CLAIMED_COUNT,
        pendingCount: PENDING_COUNT,
      },
      headers: { Authorization: null },
    }),
    [dailyBonus?.bonusId],
  )
  const options = checkBonusOptions(variables)

  const { data, isLoading, error } = useQuery({
    ...options,
    queryKey: checkBonusQueryKey(variables),
    enabled: !!dailyBonus?.bonusId,
  })

  return {
    data,
    error,
    isLoading,
  }
}

export { useBonusOptionsQuery }
