import { cn } from '@vgw/tailwind-merger'
import { HTMLAttributes, ReactNode } from 'react'

export interface BodyProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode
  isContentPadded?: boolean
}

const Body = ({ className, children, ...props }: BodyProps) => (
  <div
    className={cn(
      'flex h-full w-full flex-col items-start gap-3 rounded-lg',
      className,
    )}
    {...props}
    data-testid="drawer-body"
  >
    {children}
  </div>
)

Body.displayName = 'DrawerBody'

export { Body }
