import { IconButton } from '@vgw/multibrand-fe-icon-button'
import { Link } from 'react-router-dom'
import { GAME_PLAY_PATH } from '@/config/paths'
import { GameCardData } from '@/features/game-api/use-game-info-query'
import { useLingui } from '@lingui/react/macro'

type GameTitleProps = {
  gameCard: GameCardData
  handleToggleFavorite: () => void
}

export const GameTitle = ({
  gameCard,
  handleToggleFavorite,
}: GameTitleProps) => {
  const { t } = useLingui()
  const { isFavorite, launchUrl, name, gameId } = gameCard

  return (
    <div className="flex h-11 items-center justify-between self-stretch">
      <h1
        className="text-2xl font-bold leading-9 tracking-normal"
        data-testid="game-info-title"
      >
        {name}
      </h1>
      <div className="flex items-center gap-3">
        <IconButton
          aria-label={
            isFavorite ? t`Remove from favorites` : t`Add to favorites`
          }
          icon={isFavorite ? 'coloured:favourite-filled' : 'favourite'}
          hoverIcon={
            isFavorite
              ? 'coloured:favourite-filled-hover'
              : 'coloured:favourite'
          }
          size="lg"
          variant="outline"
          data-testid="game-info-favorite-button"
          onClick={handleToggleFavorite}
        />

        <IconButton
          asChild
          aria-label={t`Play now`}
          size="3xl"
          icon="play-filled"
          iconSize="4xl"
          data-testid="game-info-launch-button"
        >
          <Link to={`${GAME_PLAY_PATH}/${gameId}`} state={{ launchUrl }} />
        </IconButton>
      </div>
    </div>
  )
}
