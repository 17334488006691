import * as ToastPrimitive from '@radix-ui/react-toast'
import { cn } from '@vgw/tailwind-merger'
import { CustomIcon } from '@vgw/multibrand-fe-icon'
import { ComponentProps, ReactElement } from 'react'

type ToastCloseProps = ComponentProps<typeof ToastPrimitive.Close> & {
  icon?: ReactElement
}

const ToastClose = ({ className, icon, ...props }: ToastCloseProps) => {
  return (
    <ToastPrimitive.Close {...props} className={cn('size-fit', className)}>
      {icon ?? <CustomIcon name="cross-1" size="sm" />}
    </ToastPrimitive.Close>
  )
}

export { ToastClose }
