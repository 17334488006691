import { Trans } from '@lingui/react/macro'
import { IconButton } from '@vgw/multibrand-fe-icon-button'

export const PendingVerificationAndLocked = () => {
  return (
    <div className="flex items-start gap-3" data-testid="grace-period">
      <div>
        <IconButton icon="clock" variant="ghost" className="p-0" />
      </div>
      <p>
        <span className="font-bold">
          <Trans>Verification pending.</Trans>
        </span>{' '}
        <Trans>
          To continue claiming the Daily Bonus your identity must be verified.
        </Trans>
      </p>
    </div>
  )
}
