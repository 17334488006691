import { ReactNode, useContext, useEffect } from 'react'
import { Outlet, ScrollRestoration, useLocation } from 'react-router-dom'
import { setUTMs } from '@vgw/mar-utm-tracker'
import { useBrandFeatures } from '@/lib/use-brand-features'
import { useStoreLaunch } from '@/features/store/use-store-launch'
import { useOidc } from '@/features/oidc/oidc'
import { useCoinsStateQuery } from '@/features/coins-state/use-coins-state-query'
import { SelectedCoinTypeContext } from '@/selected-coin-type-context'
import { GAME_PLAY_PATH } from '@/config/paths'
import { HeaderProps } from '@/features/header/types'
import { FooterProps } from '@/features/footer/footer'
import { Store } from '@/features/store/store'
import { Content, ContentProps } from './content'

interface RootProps {
  renderContent?: (props: ContentProps) => ReactNode
  renderFooter?: (props: FooterProps) => ReactNode
  renderHeader?: (props: HeaderProps) => ReactNode
}

export const Root = ({
  renderContent = (props) => (
    <Content {...props}>
      <Outlet />
    </Content>
  ),
  renderHeader,
  renderFooter,
}: RootProps) => {
  const { login, logout, oidcTokens } = useOidc()

  const { data: coinsState, error, isLoading } = useCoinsStateQuery()

  const { selectedCoinType, setSelectedCoinType } = useContext(
    SelectedCoinTypeContext,
  )
  const location = useLocation()
  const { currentTab } = useStoreLaunch()

  const { isSweepsCoinsEnabled } = useBrandFeatures()

  useEffect(() => {
    // set UTMs on every client navigations
    setUTMs()
  }, [location])

  return (
    <div
      className="flex min-h-screen w-full flex-col items-center bg-surface-base"
      data-testid="root-layout"
    >
      <ScrollRestoration />
      {renderHeader &&
        renderHeader({
          user: {
            userName: oidcTokens?.decodedIdToken.name as string,
            // TODO: replace with email from token,
            // for now it is preferred_username which equals to email in some cases
            userEmail: oidcTokens?.decodedIdToken.preferred_username as string,
            login: async () =>
              login && login({ doesCurrentHrefRequiresAuth: false }),
            logout: async () => logout && logout({ redirectTo: 'home' }),
          },
          coinTypeInfo: {
            amounts: coinsState,
            error,
            isLoading,
            selectedCoinType: selectedCoinType,
            gamePlayPath: GAME_PLAY_PATH,
            onCoinTypeChange: () =>
              setSelectedCoinType(selectedCoinType === 'GC' ? 'SC' : 'GC'),
            isSweepsCoinsEnabled,
          },
          className: 'sticky inset-x-0 top-0',
        })}
      {renderContent && renderContent({})}
      {renderFooter &&
        renderFooter({ isSweepsCoinsEnabled: isSweepsCoinsEnabled })}
      {currentTab && <Store />}
    </div>
  )
}
