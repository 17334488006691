import { SelectedCoinTypeContextProvider } from '@/selected-coin-type-context'
import { I18nProvider } from '@lingui/react'
import { i18n } from '@lingui/core'
import { OidcProvider } from '@/features/oidc/oidc'
import { Tracking } from '@/tracking'
import { ReactQueryProvider } from '@/providers/react-query-povider'
import {
  createBrowserRouter,
  redirect,
  RouterProvider,
  Outlet,
} from 'react-router-dom'
import { Root } from '@/layout/root'
import Home from '@/pages/home/home'
import {
  GAME_CATEGORY_PATH,
  GAME_PATH,
  GEO_BLOCK_PATH,
  PURCHASE_HISTORY_PATH,
  SDD_FORM_PATH,
  USER_LOGIN_PATH,
  USER_REGISTER_PATH,
} from '@/config/paths'
import GamePlay from '@/pages/game-play/game-play'
import { CategoryPage } from '@/pages/category'
import ErrorPage from '@/pages/error/error'
import GeoBlock from '@/pages/geo-block/geo-block'
import { Sdd } from '@/pages/account/sdd/sdd'
import { protectedRouteLoader } from '@/features/oidc/protected-route-loader'
import { StoreTab } from '@/features/store/store-tab-enum'
import { OutOfGameHeader } from './features/header/out-of-game-header/out-of-game-header'
import Footer from '@/features/footer/footer'
import { InGameHeader } from './features/header/in-game-header/in-game-header'
import { Content, ContentProps } from './layout/content'
import { redirectToAuthenticationPages } from '@/redirect-to-authentication-pages'
import { PurchaseHistory } from './pages/purchase-history/purchase-history'
import { ToastProvider } from '@vgw/multibrand-fe-toast'

function redirectToStore(request: Request, value: StoreTab) {
  const url = new URL(request.url)
  url.searchParams.set('store', value)
  return redirect(`/?${url.searchParams.toString()}`)
}

const router = createBrowserRouter(
  [
    {
      path: '/',
      element: <Root renderHeader={OutOfGameHeader} renderFooter={Footer} />,
      errorElement: <ErrorPage />,
      loader: protectedRouteLoader,
      children: [
        {
          path: '',
          element: <Home />,
        },
      ],
    },
    {
      path: '/store',
      errorElement: <ErrorPage />,
      loader: async (params) => {
        await protectedRouteLoader(params)
        return redirectToStore(params.request, StoreTab.buyGoldCoins)
      },
      children: [
        {
          path: 'buy-gold-coins',
          loader: ({ request }) =>
            redirectToStore(request, StoreTab.buyGoldCoins),
        },
        {
          path: 'play-for-free',
          loader: ({ request }) =>
            redirectToStore(request, StoreTab.playForFree),
        },
      ],
    },
    {
      path: '/',
      element: <Root renderHeader={InGameHeader} renderFooter={Footer} />,
      errorElement: <ErrorPage />,
      loader: protectedRouteLoader,
      children: [
        {
          path: GAME_PATH,
          element: <GamePlay />,
          loader: protectedRouteLoader,
        },
      ],
    },
    {
      path: '/',
      element: <Root renderHeader={OutOfGameHeader} renderFooter={Footer} />,
      errorElement: <ErrorPage />,
      loader: protectedRouteLoader,
      children: [
        {
          path: GAME_CATEGORY_PATH,
          element: <CategoryPage />,
        },
      ],
    },
    {
      path: '/',
      element: (
        <Root
          renderFooter={Footer}
          renderContent={(props: ContentProps) => (
            <Content
              {...props}
              mainClassName="bg-page-small-background bg-contain bg-top bg-no-repeat"
              contentClassName="grow flex-col"
            >
              <Outlet />
            </Content>
          )}
        />
      ),
      loader: protectedRouteLoader,
      children: [
        {
          path: GEO_BLOCK_PATH,
          element: <GeoBlock />,
        },
      ],
    },
    {
      path: '/',
      element: (
        <Root
          renderContent={(props) => (
            <Content {...props} mainClassName="bg-surface-tertiary">
              <Outlet />
            </Content>
          )}
        />
      ),
      loader: protectedRouteLoader,
      children: [
        {
          path: SDD_FORM_PATH,
          element: <Sdd />,
        },
      ],
    },
    {
      path: PURCHASE_HISTORY_PATH,
      element: <PurchaseHistory />,
      loader: protectedRouteLoader,
    },
    {
      path: USER_LOGIN_PATH,
      loader: ({ request }) => {
        return redirectToAuthenticationPages(request)
      },
    },
    {
      path: USER_REGISTER_PATH,
      loader: ({ request }) => {
        return redirectToAuthenticationPages(request)
      },
    },
  ],
  { basename: import.meta.env.BASE_URL },
)

export const App = ({ amplitudeKey }: { amplitudeKey: string }) => {
  return (
    <SelectedCoinTypeContextProvider>
      <I18nProvider i18n={i18n}>
        <OidcProvider>
          <Tracking apiKey={amplitudeKey}>
            <ReactQueryProvider>
              <ToastProvider>
                <RouterProvider router={router} />
              </ToastProvider>
            </ReactQueryProvider>
          </Tracking>
        </OidcProvider>
      </I18nProvider>
    </SelectedCoinTypeContextProvider>
  )
}
