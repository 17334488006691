import { ElementRef, forwardRef } from 'react'
import { Content as PrimitiveContent } from '@radix-ui/react-dialog'
import { cn } from '@vgw/tailwind-merger'
import { cva, type VariantProps } from 'class-variance-authority'

interface ContentProps
  extends React.ComponentPropsWithoutRef<typeof PrimitiveContent>,
    VariantProps<typeof variants> {}

const variants = cva(
  [
    'bg-surface-neutral',
    'text-content-base',
    'fixed',
    'z-50',
    'h-full',
    'w-full',
    'focus:outline-none',
    'shadow-[0_0_16px_rgba(0,0,0,0.6)]',
    'flex',
    'flex-col',
    'overflow-y-auto',
  ],
  {
    variants: {
      position: {
        right:
          'data-[state=open]:slide-in-from-right data-[state=closed]:slide-out-to-right right-0 top-0 max-w-sm',
        left: 'data-[state=open]:slide-in-from-left data-[state=closed]:slide-out-to-left left-0 top-0 max-w-sm',
        top: 'data-[state=open]:slide-in-from-top data-[state=closed]:slide-out-to-top left-0 top-0 max-h-96 w-full',
        bottom:
          'data-[state=open]:slide-in-from-bottom data-[state=closed]:slide-out-to-bottom bottom-0 left-0 max-h-96 w-full',
      },
      insetContent: {
        true: 'p-6 pt-8',
        false: '',
      },
    },
    defaultVariants: {
      position: 'right',
      insetContent: true,
    },
  },
)

const Content = forwardRef<ElementRef<typeof PrimitiveContent>, ContentProps>(
  ({ className, children, position, insetContent, ...props }, ref) => (
    <PrimitiveContent
      className={cn(
        'data-[state=open]:animate-in data-[state=open]:fade-in-0 transition',
        'data-[state=closed]:animate-out',
        variants({ position, insetContent }),
        className,
      )}
      ref={ref}
      aria-modal={true}
      data-testid="drawer-content"
      {...props}
    >
      {children}
    </PrimitiveContent>
  ),
)

Content.displayName = 'DrawerContent'

export { Content }
