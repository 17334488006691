import { zodResolver } from '@hookform/resolvers/zod'
import { I18n } from '@lingui/core'
import { msg } from '@lingui/core/macro'
import { useLingui } from '@lingui/react'
import { Trans } from '@lingui/react/macro'
import { Button } from '@vgw/multibrand-fe-button'
import { DatePicker } from '@vgw/multibrand-fe-date-picker'
import { FormSection } from '@vgw/multibrand-fe-form-section'
import {
  addDays,
  endOfDay,
  formatISO,
  isAfter,
  isBefore,
  startOfDay,
  subMonths,
} from 'date-fns'
import { useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { z } from 'zod'
import { usePurchaseHistoryInfiniteQuery } from '../use-purchase-history-infinite-query'

const defaultDisabledDateMatcher = subMonths(new Date(), 6)
const dateRangeError = msg`Dates must be within the last 6 months. Please choose a date within this range.`

const PurchaseFiltersSchema = (_: I18n['_']) =>
  z
    .object({
      from: z
        .date()
        .nullable()
        .refine(
          (from) =>
            from === null ||
            isAfter(new Date(from), defaultDisabledDateMatcher),
          {
            message: _(dateRangeError),
          },
        )
        .refine(
          (from) =>
            from === null || isBefore(new Date(from), endOfDay(new Date())),
          {
            message: _(dateRangeError),
          },
        )
        .optional(),
      to: z
        .date()
        .nullable()
        .refine(
          (to) =>
            to === null || isAfter(new Date(to), defaultDisabledDateMatcher),
          {
            message: _(dateRangeError),
          },
        )
        .refine(
          (to) => to === null || !isAfter(new Date(to), endOfDay(new Date())),
          {
            message: _(dateRangeError),
          },
        )
        .optional(),
    })
    .superRefine((schema, context) => {
      if (schema.from && schema.to) {
        if (!isBefore(schema.from, schema.to)) {
          context.addIssue({
            code: 'custom',
            path: ['from'],
            message: _(
              msg`The start date cannot be later than the end date. Please select a valid range.`,
            ),
          })
        }
        if (!isAfter(schema.to, schema.from)) {
          context.addIssue({
            code: 'custom',
            path: ['to'],
            message: _(
              msg`The end date cannot be earlier than the start date. Please select a valid range.`,
            ),
          })
        }
      }
    })

export type PurchaseFiltersValues = z.infer<
  ReturnType<typeof PurchaseFiltersSchema>
>

export type PurchaseFiltersContentProps = {
  filters: PurchaseFiltersValues | null
  onShowAllResults: (data: PurchaseFiltersValues | null) => void
}

export const PurchaseFiltersContent = ({
  filters,
  onShowAllResults,
}: PurchaseFiltersContentProps) => {
  const { _ } = useLingui()

  const { reset, handleSubmit, formState, control, watch } =
    useForm<PurchaseFiltersValues>({
      mode: 'all',
      resolver: zodResolver(useMemo(() => PurchaseFiltersSchema(_), [_])),
      defaultValues: filters ? filters : { to: null, from: null },
    })
  const { to, from } = watch()
  const { data, isLoading } = usePurchaseHistoryInfiniteQuery({
    enabled: !!(from && to && formState.isValid),
    query:
      from && to
        ? {
            to: formatISO(to),
            from: formatISO(from),
          }
        : undefined,
  })

  const onClearAll = () => {
    reset({ from: null, to: null }, { keepDefaultValues: false })
  }

  const onSubmit = ({ from, to }: PurchaseFiltersValues) => {
    onShowAllResults(from && to ? { from, to } : null)
  }

  const results = useMemo(() => data?.pages.at(-1)?.count, [data])

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      data-testid="purchase-filters-content"
      className="flex w-full flex-1 flex-col"
    >
      <div className="flex w-full flex-1 flex-col gap-3">
        <p className="text-center font-display text-2xl font-bold">
          <Trans>Filter</Trans>
        </p>
        <p className="text-center font-body text-base font-light">
          <Trans>2 weeks of history is available to filter</Trans>
        </p>
        <Controller
          name="from"
          control={control}
          render={({ field }) => (
            <FormSection data-testid="purchase-filters-from">
              <DatePicker
                accessibleTriggerLabel={_(msg`Choose a date to filter from`)}
                htmlFor="from"
                label={<Trans>From</Trans>}
                error={!!formState.errors.from}
                selectedDate={field.value}
                setSelectedDate={(from) =>
                  field.onChange(from ? startOfDay(from) : null)
                }
                disabled={
                  to
                    ? { before: defaultDisabledDateMatcher, after: to }
                    : {
                        before: defaultDisabledDateMatcher,
                        after: new Date(),
                      }
                }
              />
              {!!formState.errors.from && (
                <FormSection.ErrorMessage
                  className="text-sm"
                  data-testid="from-filter-error"
                >
                  {formState.errors.from.message}
                </FormSection.ErrorMessage>
              )}
            </FormSection>
          )}
        />
        <Controller
          name="to"
          control={control}
          render={({ field }) => (
            <FormSection data-testid="purchase-filters-to">
              <DatePicker
                accessibleTriggerLabel={_(msg`Choose a date to filter to`)}
                htmlFor="to"
                label={<Trans>To</Trans>}
                error={!!formState.errors.to}
                selectedDate={field.value}
                disabled={
                  from
                    ? { before: from, after: new Date() }
                    : {
                        before: addDays(defaultDisabledDateMatcher, 1),
                        after: new Date(),
                      }
                }
                setSelectedDate={(to) =>
                  field.onChange(to ? endOfDay(to) : null)
                }
              />
              {!!formState.errors.to && (
                <FormSection.ErrorMessage
                  className="text-sm"
                  data-testid="to-filter-error"
                >
                  {formState.errors.to.message}
                </FormSection.ErrorMessage>
              )}
            </FormSection>
          )}
        />
      </div>
      <div className="flex w-full flex-col gap-2 sm:flex-row sm:justify-between">
        <Button
          variant="ghost"
          onClick={onClearAll}
          disabled={to === null && from === null}
        >
          <Trans>Clear all</Trans>
        </Button>
        <Button
          type="submit"
          loading={isLoading}
          className="sm:w-[207px]"
          data-testid="purchase-filters-submit-button"
        >
          {from && to && typeof results === 'number' ? (
            <Trans>Show {results} results</Trans>
          ) : (
            <Trans>Show all results</Trans>
          )}
        </Button>
      </div>
    </form>
  )
}
