import { ErrorBoundary } from 'react-error-boundary'
import { Trans } from '@lingui/react/macro'
import { useGameInfoQuery } from '@/features/game-api/use-game-info-query'
import {
  Drawer,
  DrawerBody,
  DrawerClose,
  DrawerContent,
  DrawerOverlay,
} from '@vgw/multibrand-fe-drawer'
import { GameTitle } from './game-title'
import { GameConfig } from './game-config/game-config'
import { MoreGames } from './more-games'
import { useToggleGameFavoriteMutation } from './use-toggle-game-favorite-mutation'
import { useGameInfoDrawerLaunch } from './use-game-info-drawer-launch'

export const GameInfoDrawer = () => {
  const { currentGameInfoId, close } = useGameInfoDrawerLaunch()

  if (!currentGameInfoId) {
    return null
  }

  return (
    <Drawer open onOpenChange={close}>
      <DrawerOverlay />
      <DrawerContent position="bottom" insetContent={true}>
        {/*todo: error handling*/}
        <DrawerClose />
        <DrawerBody>
          <ErrorBoundary fallback={<Trans>Something went wrong</Trans>}>
            <GameInfoContent gameId={currentGameInfoId} />
          </ErrorBoundary>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

type GameInfoContentProps = {
  gameId: string | null
}

export const GameInfoContent = ({ gameId }: GameInfoContentProps) => {
  const { data, isLoading, error } = useGameInfoQuery(gameId)
  const { toggleFavoriteMutation } = useToggleGameFavoriteMutation(gameId)

  if (isLoading) {
    return null
  }

  if (!data) {
    return null
  }

  if (error) {
    throw error
  }

  const handleToggleFavorite = () =>
    toggleFavoriteMutation(data.gameId, data.isFavorite)

  return (
    <div
      className="flex justify-center self-stretch"
      data-testid="game-info-content"
    >
      <div
        data-testid="game-card-content"
        className="flex w-full max-w-screen-2xl flex-col items-start gap-4 p-4 pt-9"
      >
        <GameTitle
          gameCard={data}
          handleToggleFavorite={handleToggleFavorite}
        />
        <p className="text-sm leading-5" data-testid="game-info-description">
          {data.description}
        </p>
        <GameConfig gameConfig={data.config} />
        <hr className="h-[0.0625rem] w-full border-border-muted" />
        <MoreGames games={data.similarGames} />
      </div>
    </div>
  )
}
