import { useCallback, useEffect, useState } from 'react'
import { SelectedItem } from '@/features/store/store'
import { useLocation, useNavigate } from 'react-router-dom'

const usePurchaseFlow = () => {
  const [selectedItem, setSelectedItem] = useState<SelectedItem>({
    itemId: '',
  })
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    setSelectedItem(location.state?.purchaseItem || { itemId: '' })
  }, [location])

  return {
    isOpen: !!selectedItem.itemId,
    selectedItem,
    openPurchaseFlow: useCallback(
      (item: SelectedItem) => {
        navigate(location.pathname + location.search, {
          state: { purchaseItem: item },
        })
      },
      [navigate, location],
    ),
    closePurchaseFlow: useCallback(() => {
      navigate(location.pathname + location.search, {
        state: { purchaseItem: { itemId: '' } },
      })
    }, [navigate, location]),
  }
}

export { usePurchaseFlow }
