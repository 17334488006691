import { Trans } from '@lingui/react/macro'
import { Button } from '@vgw/multibrand-fe-button'
import { loadBrandConfig } from '@/config/config'
import { StoreData } from '@/features/store/use-store-query'
import { StoreTab } from '@/features/store/store-tab-enum'
import { SpecialOfferItemsSet } from '@/features/store/special-offer-items-set'
import { RegularStoreItemsSet } from '@/features/store/regular-store-items-set/regular-store-items-set'
import { SelectedItem } from '../store'
import { BuyGoldCoinsSkeleton } from './buy-gold-coins-skeleton'

const config = await loadBrandConfig()

type StoreProps = {
  offers: StoreData | undefined
  isLoading: boolean
  changeTab: (tab: StoreTab) => void
  setSelectedItem: (item: SelectedItem) => void
}

export const BuyGoldCoins = ({
  offers,
  isLoading,
  changeTab,
  setSelectedItem,
}: StoreProps) => {
  const { regularStoreItems = [], specialOfferSets = [] } = offers || {}

  return (
    <div
      data-testid="buy-gold-coins-wrapper"
      className="mt-3 flex flex-col gap-6"
    >
      <div>
        <h2 className="font-display text-lg font-bold leading-7 text-content-base">
          <Trans>
            <span className="capitalize">{config.name.toLowerCase()}</span> is
            always FREE to play
          </Trans>
        </h2>
        <div className="flex items-center">
          <span className="font-body text-[15px] leading-4 text-content-base">
            <Trans>No purchase necessary.</Trans>
          </span>
          <Button
            onClick={() => changeTab(StoreTab.playForFree)}
            variant="secondary"
            size="sm"
          >
            <Trans>More info</Trans>
          </Button>
        </div>
      </div>
      <div className="flex flex-col gap-6">
        {isLoading ? (
          <BuyGoldCoinsSkeleton />
        ) : (
          <>
            {specialOfferSets.map((item) => (
              <SpecialOfferItemsSet
                key={item.id}
                name={item.name}
                validUntil={
                  item.validUntil ? new Date(item.validUntil) : undefined
                }
                claim={item.claim}
                items={item.items}
                onItemClick={(itemId) =>
                  setSelectedItem({ type: 'special-offer-item', itemId })
                }
              />
            ))}
            <RegularStoreItemsSet
              items={regularStoreItems}
              onItemClick={(itemId) =>
                setSelectedItem({ type: 'regular-store-item', itemId })
              }
            />
          </>
        )}
      </div>
    </div>
  )
}
