import {
  PurchaseHistoryCard,
  PurchaseHistoryCardProps,
} from '@/features/purchase-history/purchase-history-card'

export interface PurchaseHistoryListProps {
  title: string
  items: PurchaseHistoryCardProps[]
}

export const PurchaseHistoryList = ({
  title,
  items,
}: PurchaseHistoryListProps) => {
  return (
    <div
      className="flex flex-col gap-2 pb-2"
      data-testid="purchase-history-list"
    >
      <h3
        className="text-content-tertiary-muted sticky top-[144px] bg-surface-base px-4 py-1 font-display text-sm font-medium leading-5"
        data-testid="purchase-history-list-date"
      >
        {title}
      </h3>
      <div className="flex flex-col gap-1 px-4">
        {items.map((item) => (
          <PurchaseHistoryCard key={item.id} {...item} />
        ))}
      </div>
    </div>
  )
}
