import { ReactNode, useContext } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Trans } from '@lingui/react/macro'
import { SelectedCoinTypeContext } from '@/selected-coin-type-context'
import { useGameInfoQuery } from '@/features/game-api/use-game-info-query'
import {
  Drawer,
  DrawerBody,
  DrawerClose,
  DrawerContent,
  DrawerOverlay,
} from '@vgw/multibrand-fe-drawer'
import { GameTitle } from './game-title'
import { GameConfig } from './game-config/game-config'
import { MoreGames } from './more-games'
import { useToggleGameFavoriteMutation } from './use-toggle-game-favorite-mutation'

export interface GameCardProps {
  gameId: string
  triggerComponent: ReactNode
}

export const GameInfoDrawer = ({ gameId, triggerComponent }: GameCardProps) => {
  return (
    <Drawer trigger={triggerComponent}>
      <DrawerOverlay />
      <DrawerContent position="bottom" insetContent={true}>
        {/*todo: error handling*/}
        <DrawerClose />
        <DrawerBody>
          <ErrorBoundary fallback={<Trans>Something went wrong</Trans>}>
            <GameInfoContent gameId={gameId} />
          </ErrorBoundary>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

const GameInfoContent = ({ gameId }: { gameId: string }) => {
  const { selectedCoinType } = useContext(SelectedCoinTypeContext)
  const { data, isLoading, error } = useGameInfoQuery(gameId)
  const { toggleFavorite, isPending: isFavoriteTogglePending } =
    useToggleGameFavoriteMutation(gameId)

  if (isLoading) return null

  if (!data) {
    return null
  }

  if (error) {
    throw error
  }

  const isFavorite = isFavoriteTogglePending
    ? !data.isFavorite
    : data.isFavorite

  const handleToggleFavorite = () =>
    toggleFavorite({
      gameId: data.gameId,
      isFavorite,
    })

  return (
    <div className="flex justify-center self-stretch">
      <div
        data-testid="game-card-content"
        className="flex w-full max-w-screen-2xl flex-col items-start gap-4 p-4 pt-9"
      >
        <GameTitle
          gameCard={data}
          isFavorite={isFavorite}
          handleToggleFavorite={handleToggleFavorite}
        />
        <p className="text-sm leading-5">{data.description}</p>
        <GameConfig
          gameConfig={data.config}
          selectedCoinType={selectedCoinType}
        />
        <hr className="h-[0.0625rem] w-full border-border-muted" />
        <MoreGames games={data.similarGames} />
      </div>
    </div>
  )
}
