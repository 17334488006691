import { useSearchParams } from 'react-router-dom'

export const GameInfoDrawerParam = 'game-info'

export const useGameInfoDrawerLaunch = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const close = () =>
    setSearchParams(
      (searchParams) => {
        const params = new URLSearchParams(searchParams)
        params.delete(GameInfoDrawerParam)
        return params
      },
      { preventScrollReset: true },
    )

  const open = (gameId: string) =>
    setSearchParams(
      (searchParams) => {
        const params = new URLSearchParams(searchParams)
        params.set(GameInfoDrawerParam, gameId)
        return params
      },
      { preventScrollReset: true },
    )

  const currentGameInfoId = searchParams.get(GameInfoDrawerParam)

  return {
    open,
    close,
    currentGameInfoId,
  }
}
