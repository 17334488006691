import { cva } from 'class-variance-authority'
import { cn } from '@vgw/tailwind-merger'
import { TextFieldContext } from './text-field.context'
import { useContext } from 'react'

const textFieldVariants = cva(
  cn(
    'w-full',
    'bg-form-input-surface',
    'text-form-input-content-base',
    'disabled:bg-disabled peer bg-transparent pb-1 outline-none',
  ),
  {
    variants: {
      size: {
        sm: 'h-[46px] px-2 py-1.5 pt-5 text-sm font-normal leading-5',
        md: 'h-14 px-3 py-2 pt-6 text-base font-normal leading-6',
      },
      hasIcon: {
        true: null,
        false: null,
      },
    },
    compoundVariants: [
      {
        size: 'sm',
        hasIcon: true,
        className: 'pr-8',
      },
      {
        size: 'md',
        hasIcon: true,
        className: 'pr-9',
      },
    ],
    defaultVariants: {
      size: 'sm',
    },
  },
)

export const useFieldStyles = () => {
  const { size, hasIcon } = useContext(TextFieldContext)
  return textFieldVariants({
    size,
    hasIcon,
  })
}
