import Lobby from '@/features/lobby/lobby'
import { useJackpotsQuery } from '@/features/jackpots/use-jackpots-query'
import { useBrandFeatures } from '@/lib/use-brand-features'
import { LobbyHeroBanner } from '@/features/hero-banner/hero-banner'
import { useCategoriesQuery } from '@/pages/home/use-categories-query'

const Home = () => {
  const { isLoading, categories } = useCategoriesQuery()
  const { data: jackpots } = useJackpotsQuery()
  const brandFeatures = useBrandFeatures()

  return (
    <div className="w-full">
      <div className="overflow-x-clip xl:px-4">
        {brandFeatures.isHeroBannerEnabled ? <LobbyHeroBanner /> : null}
      </div>
      <div data-chromatic="ignore">
        <Lobby
          showLoading={isLoading}
          categories={categories}
          jackpots={jackpots}
        />
      </div>
    </div>
  )
}

export default Home
