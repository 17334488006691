import { Close as PrimitiveClose } from '@radix-ui/react-dialog'
import { useLingui } from '@lingui/react/macro'
import { IconButton } from '@vgw/multibrand-fe-icon-button'
import { ComponentProps } from 'react'

const Close = ({ ...props }: ComponentProps<typeof PrimitiveClose>) => {
  const { t } = useLingui()

  return (
    <PrimitiveClose asChild {...props}>
      <IconButton
        data-testid="drawer-close"
        className="pointer-events-auto absolute right-3 top-3"
        size="sm"
        icon="cross-1"
        variant="ghost"
        aria-label={t`Close drawer button`}
      />
    </PrimitiveClose>
  )
}

Close.displayName = 'DrawerClose'

export { Close }
