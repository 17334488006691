import { buildIFrameUrlAttribute } from './build-iframe-url-attribute'
import { PurchaseData } from './use-purchase-query'
import { CircleLoadingAnimation } from '@vgw/multibrand-fe-circle-loading-animation'
import { useEffect } from 'react'

type PurchaseContentProps = {
  data: PurchaseData | undefined
  error: string | undefined
  isLoading: boolean
  onPurchaseError: () => void
}

export const PurchaseContent = ({
  data,
  error,
  isLoading,
  onPurchaseError,
}: PurchaseContentProps) => {
  useEffect(() => {
    if (data && !data.paymentRedirectUrl) {
      onPurchaseError()
    }
  }, [data, onPurchaseError])

  useEffect(() => {
    if (error) {
      onPurchaseError()
    }
  }, [error, onPurchaseError])

  if (isLoading)
    return (
      <div className="flex flex-1 items-center justify-center">
        <CircleLoadingAnimation />
      </div>
    )

  const url = data?.paymentRedirectUrl

  return (
    <iframe
      data-testid="purchase-iframe"
      title="Purchase"
      className="h-full w-full"
      allow="payment"
      src={url && buildIFrameUrlAttribute(url)}
    />
  )
}
