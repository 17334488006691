import { Category } from '@/features/category/category'
import { useContext } from 'react'
import { SkeletonLobby } from './skeleton-lobby'
import { Jackpots } from '@/features/jackpots/use-jackpots-query'
import { LayoutFactory } from '@/features/category/layout-factory/layout-factory'
import { SelectedCoinTypeContext } from '@/selected-coin-type-context'
import { GameCategory } from '@/features/game-api/use-gallery-query'
import { getGamesWithJackpotValue } from '@/features/jackpots/get-games-with-jackpot-value'

interface LobbyProps {
  showLoading: boolean
  categories?: GameCategory[]
  jackpots?: Jackpots
}

export default function Lobby({
  showLoading,
  categories = [],
  jackpots,
}: LobbyProps) {
  const { selectedCoinType } = useContext(SelectedCoinTypeContext)

  if (showLoading) return <SkeletonLobby />

  return (
    <section
      className="flex w-full flex-col gap-8 px-2 py-0 sm:px-4 md:px-6 lg:py-4"
      data-testid="lobby"
    >
      {categories.map(({ name, games, description, iconUrl, layout }) => (
        <Category
          key={name}
          name={name}
          description={description}
          iconUrl={iconUrl}
          layout={
            <LayoutFactory
              variant={layout}
              props={{
                games: getGamesWithJackpotValue(
                  games,
                  selectedCoinType,
                  jackpots,
                ),
              }}
            />
          }
        />
      ))}
    </section>
  )
}
