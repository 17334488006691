import { IconButton } from '@vgw/multibrand-fe-icon-button'
import { Trans } from '@lingui/react/macro'
import { cn } from '@vgw/tailwind-merger'

export const PurchaseHistoryHeader = () => {
  const handleBackClick = () => window.history.back()

  return (
    <div className="mb-1 flex gap-2 bg-white px-3 py-2">
      <div>
        <IconButton
          icon="arrow-left"
          variant="ghost"
          onClick={handleBackClick}
        />
      </div>
      <h1
        className={cn(
          'text-content-tertiary mr-9 mt-9 flex-1 whitespace-nowrap text-center text-2xl font-bold leading-9 transition-all duration-300 ease-in-out',
        )}
      >
        <Trans>Purchase History</Trans>
      </h1>
    </div>
  )
}
