import { NavLink, useMatch } from 'react-router-dom'
import { useLingui } from '@lingui/react/macro'
import { MessageDescriptor } from '@lingui/core'
import { Button } from '@vgw/multibrand-fe-button'
import { cn } from '@vgw/tailwind-merger'

interface SubMenuNavigationItemProps {
  label: MessageDescriptor
  path: string
}

export const SubMenuNavigationItem = ({
  label,
  path,
}: SubMenuNavigationItemProps) => {
  const { t } = useLingui()
  const isActive = useMatch(path)

  return (
    <li className="text-nowrap">
      <Button
        asChild
        size="sm"
        variant="ghost"
        className={cn(
          [
            'rounded-full',
            'text-xs',
            'font-medium',
            'leading-4',
            'hover:bg-vanilla-100',
            'active:bg-vanilla-200',
            'transition-colors',
          ],
          isActive && 'bg-vanilla-200',
        )}
      >
        <NavLink to={path}>{t(label)}</NavLink>
      </Button>
    </li>
  )
}
