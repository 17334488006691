import { useSearchParams } from 'react-router-dom'
import { useMemo } from 'react'
import { StoreTab } from '@/features/store/store-tab-enum'

function useStoreLaunch() {
  const [searchParams, setSearchParams] = useSearchParams()

  const close = () =>
    setSearchParams(
      (searchParams) => {
        const params = new URLSearchParams(searchParams)
        params.delete('store')
        return params
      },
      { preventScrollReset: true },
    )

  const open = () =>
    setSearchParams(
      (searchParams) => {
        const params = new URLSearchParams(searchParams)
        params.set('store', StoreTab.buyGoldCoins)
        return params
      },
      { preventScrollReset: true },
    )

  const changeTab = (value: StoreTab) =>
    setSearchParams(
      (searchParams) => {
        const params = new URLSearchParams(searchParams)
        params.set('store', value)
        return params
      },
      { preventScrollReset: true },
    )

  const currentTab = useMemo(() => {
    if (searchParams.get('store') === StoreTab.buyGoldCoins) {
      return StoreTab.buyGoldCoins
    }
    if (searchParams.get('store') === StoreTab.playForFree) {
      return StoreTab.playForFree
    }

    return undefined
  }, [searchParams])

  return {
    close,
    open,
    currentTab,
    changeTab,
  }
}

export { useStoreLaunch }
