import { cn } from '@vgw/tailwind-merger'
import InputMask, { Props as MaskProps } from 'react-input-mask'
import { forwardRef } from 'react'
import { useFieldStyles } from '../use-field-styles'

export interface InputProps extends MaskProps {}

const MaskedInput = forwardRef<HTMLInputElement, InputProps>(
  ({ className, ...props }, ref) => {
    const styles = useFieldStyles()

    return (
      <InputMask
        inputRef={ref}
        className={cn(styles, className)}
        placeholder={' '}
        {...props}
      />
    )
  },
)

MaskedInput.displayName = 'TextField.MaskedInput'

export { MaskedInput }
