import { useSearchParams } from 'react-router-dom'
import { useMemo } from 'react'
import { StoreTab } from '@/features/store/store-tab-enum'

function useStoreLaunch() {
  const [searchParams, setSearchParams] = useSearchParams()

  const close = () =>
    setSearchParams((searchParams) => {
      searchParams.delete('store')
      return searchParams
    })

  const open = () =>
    setSearchParams((searchParams) => {
      searchParams.set('store', StoreTab.buyGoldCoins)
      return searchParams
    })

  const changeTab = (value: StoreTab) =>
    setSearchParams((searchParams) => {
      searchParams.set('store', value)
      return searchParams
    })

  const currentTab = useMemo(() => {
    if (searchParams.get('store') === StoreTab.buyGoldCoins) {
      return StoreTab.buyGoldCoins
    }
    if (searchParams.get('store') === StoreTab.playForFree) {
      return StoreTab.playForFree
    }

    return undefined
  }, [searchParams])

  return {
    close,
    open,
    currentTab,
    changeTab,
  }
}

export { useStoreLaunch }
