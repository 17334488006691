export type MobileMenuValues =
  | 'home'
  | 'store'
  | 'redeem'
  | 'search'
  | 'categories'

export const urlToMobileMenuValue: Record<
  string,
  Exclude<MobileMenuValues, 'store'>
> = {
  '/': 'home',
  '/redeem': 'redeem',
  '/search': 'search',
  '/categories': 'categories',
}
