import {
  ErrorResponse,
  getFavoriteCategoryViewOptions,
} from '@/apis/gap-gallery-view'
import { getBrandConfig } from '@/config/config'
import { useSelectedCoinType } from '@/use-selected-coin-type'
import { useQuery } from '@tanstack/react-query'

export const useFavoriteCategoryQueryOptions = () => {
  const config = getBrandConfig()
  const selectedCoinType = useSelectedCoinType()

  return getFavoriteCategoryViewOptions({
    query: {
      coinType: selectedCoinType,
    },
    path: {
      brandCode: config.code,
    },
  })
}

export const useFavoriteCategoryQuery = () => {
  const options = useFavoriteCategoryQueryOptions()

  const { data, isLoading, error } = useQuery({
    ...options,
    retry: (failureCount, error) => {
      if ((error as unknown as ErrorResponse).status === 404) {
        return false
      }

      return failureCount < 3
    },
  })

  return {
    category: data,
    isLoading,
    error,
  }
}
